<template>
    <head>
        <link rel="stylesheet" href="./css/post-1303.css" />
        <link rel="stylesheet" href="./css/post-167.css" />
        <link rel="stylesheet" href="./css/post-187.css" />
        <link rel="stylesheet" href="./css/all.min.css" />
        <link rel="stylesheet" href="./css/font-awesome.min.css" />
        <link rel="stylesheet" href="./css/frontend.min.css" />
      </head>
  <div class="art-layout-cell art-content">
    <article
      id="post-178"
      class="art-post art-article post-178 page type-page status-publish hentry"
    >
      <h1 class="art-postheader">KONSULTACJA</h1>
      <div class="art-postcontent clearfix">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="178"
          class="elementor elementor-178"
        >
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-qtovxij elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="qtovxij"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-tyzkzfm"
                data-id="tyzkzfm"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-f4j7x80 elementor-widget elementor-widget-heading"
                    data-id="f4j7x80"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h1
                        class="elementor-heading-title elementor-size-default"
                      >
                        Badanie wzroku
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-66oufz8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="66oufz8"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-w8li6zm"
                data-id="w8li6zm"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-74el24l elementor-widget elementor-widget-image"
                    data-id="74el24l"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <a
                        href="http://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1.jpg"
                        data-elementor-open-lightbox="yes"
                        data-elementor-lightbox-title="DSC01241 (Copy)"
                        data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NzEzLCJ1cmwiOiJodHRwczpcL1wvb2t1bGlzdHlrYS5zaWVkbGNlLnBsXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDE3XC8wNVwvRFNDMDEyNDEtQ29weS0xLmpwZyJ9"
                      >
                        <img
                          fetchpriority="high"
                          decoding="async"
                          width="300"
                          height="169"
                          src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1-300x169.jpg"
                          class="attachment-medium size-medium wp-image-713 lazyloaded"
                          alt=""
                          sizes="(max-width: 300px) 100vw, 300px"
                          srcset="
                            https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1-300x169.jpg   300w,
                            https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1-768x432.jpg   768w,
                            https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1-1024x576.jpg 1024w,
                            https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1.jpg          1713w
                          "
                          data-ll-status="loaded"
                        /><noscript
                          ><img
                            fetchpriority="high"
                            decoding="async"
                            width="300"
                            height="169"
                            src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1-300x169.jpg"
                            class="attachment-medium size-medium wp-image-713"
                            alt=""
                            srcset="
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1-300x169.jpg   300w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1-768x432.jpg   768w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1-1024x576.jpg 1024w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01241-Copy-1.jpg          1713w
                            "
                            sizes="(max-width: 300px) 100vw, 300px"
                        /></noscript>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="has_eae_slider elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-d0e4o5j"
                data-id="d0e4o5j"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-8bxynf4 elementor-widget elementor-widget-text-editor"
                    data-id="8bxynf4"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>
                        W ramach naszych konsultacji okulistycznych – prowadzimy
                        standardowo:
                      </h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-tv9zfc0 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="tv9zfc0"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >wywiad szczegółowy z pacjentem</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >badanie ostrości wzroku</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >badanie ciśnienia gałkowego</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            ><b>badanie dna oka</b></span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >badanie refrakcji oka</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >badanie w biomikroskopie</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >gonioskopia</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-tscqfx4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="tscqfx4"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-84ldde2"
                data-id="84ldde2"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-vrog3p3 elementor-widget elementor-widget-heading"
                    data-id="vrog3p3"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h2
                        class="elementor-heading-title elementor-size-default"
                      >
                        Badanie dna oka
                      </h2>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-35pryvz elementor-widget elementor-widget-text-editor"
                    data-id="35pryvz"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>
                        Badanie tylnego odcinka oka czyli
                        <b>dna oka&nbsp;</b>wykonuje się przy pomocy
                        oftalnoskopu oraz lampy szczelinowej. Soczewka Volk
                        użyta przy tym badaniu, zapewnia szczegółowe pole
                        widzenia – pozwala szybką i nie inwazyjną ocenę nerwu
                        wzrokowego, plamki żółtej i naczyń krwionośnych.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </article>
  </div>
  <Sidebar />
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>

<style></style>
