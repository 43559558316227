import { createStore } from 'vuex'

export default createStore({
  state: {
    isActive1 : false,
    isActive2 : false,
  },
  getters: {
  },
  mutations: {
    
    handleReset(state) {
      state.isActive1 = false;
      state.isActive2 = false;
    },
    handleClick1(state){
      state.isActive1 = true;
      state.isActive2 = false;

    },
    handleClick2(state){
      state.isActive1 = false;
      state.isActive2 = true;

    }
  },
  actions: {
  },
  modules: {
  }
})
