<template>
    <head>
        <link rel="stylesheet" href="./css/post-1303.css" />
        <link rel="stylesheet" href="./css/post-167.css" />
        <link rel="stylesheet" href="./css/post-187.css" />
        <link rel="stylesheet" href="./css/all.min.css" />
        <link rel="stylesheet" href="./css/font-awesome.min.css" />
        <link rel="stylesheet" href="./css/frontend.min.css" />
      </head>
  <div class="art-layout-cell art-content">
    <article
      id="post-167"
      class="art-post art-article post-167 page type-page status-publish hentry"
    >
      <h1 class="art-postheader">ZESPÓŁ</h1>
      <div class="art-postcontent clearfix">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="167"
          class="elementor elementor-167 elementor-bc-flex-widget"
        >
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-zskwlyz elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="zskwlyz"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2jzhcj8"
                data-id="2jzhcj8"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-nutgecw elementor-widget elementor-widget-heading"
                    data-id="nutgecw"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h1
                        class="elementor-heading-title elementor-size-default"
                      >
                        Zespół NZOZ Specjalistycznej Przychodni Okulistycznej
                      </h1>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-ltlrehl elementor-widget elementor-widget-text-editor"
                    data-id="ltlrehl"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h2>Leczenie chorób oczu</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-j3cuc30 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="j3cuc30"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-866jdvm"
                data-id="866jdvm"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-9iwd2fj elementor-widget elementor-widget-image"
                    data-id="9iwd2fj"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <img
                        fetchpriority="high"
                        decoding="async"
                        width="1713"
                        height="963"
                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201713%20963'%3E%3C/svg%3E"
                        class="attachment-full size-full wp-image-688"
                        alt=""
                        data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01243-Copy-e1495059361373.jpg"
                      /><noscript
                        ><img
                          fetchpriority="high"
                          decoding="async"
                          width="1713"
                          height="963"
                          src="/wp-content/uploads/2017/05/DSC01243-Copy-e1495059361373.jpg"
                          class="attachment-full size-full wp-image-688"
                          alt=""
                      /></noscript>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="has_eae_slider elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-nyp05w3"
                data-id="nyp05w3"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-204r5yv elementor-widget elementor-widget-heading"
                    data-id="204r5yv"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h2
                        class="elementor-heading-title elementor-size-default"
                      >
                        Szanowni Państwo
                      </h2>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-0027lza elementor-widget elementor-widget-text-editor"
                    data-id="0027lza"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>
                        Dla wszystkich ludzi pracujących w naszej Przychodni
                        najważniejsze jest profesjonalne podejście do pacjenta.
                      </h3>
                      <h3>
                        Celem jaki przyświeca naszym lekarzom jest informowanie
                        o stanie zdrowia pacjenta i sposobie leczenia,
                        przedstawione w jasny i zrozumiały sposób.<br />
                        <br /><br />Badania są prowadzone i opisywane przez
                        wykwalifikowanego lekarza okulistę. Możliwe są dodatkowe
                        konsultacje, badania czy zabiegi.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-st627wj elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="st627wj"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-xb43vcj"
                data-id="xb43vcj"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-i730thw elementor-widget elementor-widget-heading"
                    data-id="i730thw"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h2
                        class="elementor-heading-title elementor-size-default"
                      >
                        O nas
                      </h2>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-hnsry0j elementor-widget elementor-widget-text-editor"
                    data-id="hnsry0j"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3 align="justify">
                        Zajmujemy się  pacjentami od 2000 r jako Niepubliczny
                        Zakład Opieki Zdrowotnej Specjalistyczna Przychodnia
                        Okulistyczna &#8211; stąd  zakres działania: diagnostyka
                        i leczenie chorób oczu.
                      </h3>
                      <h3 align="justify">
                        Nasi pacjenci to dzieci z wadami wzroku, zezem i
                        niedowidzeniem oraz osoby dorosłe w różnym wieku.<br />
                        Staramy się zapewnić im opiekę na najwyższym poziomie.
                        Szczególną troską  objęte są osoby leczone z powodu
                        jaskry,  wymagające stałego monitorowania przebiegu
                        choroby.<br /><br />Zapraszamy do skorzystania z naszej
                        oferty
                      </h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-5pas5pl elementor-widget elementor-widget-text-editor"
                    data-id="5pas5pl"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>Nasz zespół to doświadczona kadra lekarska   :</h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-jyeklxj elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="jyeklxj"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >dr Izabela Czarnojańczyk</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >dr Renata Dmowska</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >dr Joanna Drabio</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >dr Alicja Jaroszewicz</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >dr n.med. Cezary Jaroszewicz</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >dr Agnieszka Wojciechowska- Jędrzejewicz</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-n9k2d78 elementor-widget elementor-widget-spacer"
                    data-id="n9k2d78"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-spacer">
                        <div class="elementor-spacer-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-276gmd4 elementor-widget elementor-widget-text-editor"
                    data-id="276gmd4"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>Panie Ortoptystki :</h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-1etru6o elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="1etru6o"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Barbara Bieluga</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Danuta Maczugowska</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-2xkns93 elementor-widget elementor-widget-spacer"
                    data-id="2xkns93"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-spacer">
                        <div class="elementor-spacer-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-j88aj1v elementor-widget elementor-widget-text-editor"
                    data-id="j88aj1v"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>Panie Pielęgniarki :</h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-22khu95 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="22khu95"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Ewa Biernacka</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Antonina Skwara</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-p0hx4xz elementor-widget elementor-widget-spacer"
                    data-id="p0hx4xz"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-spacer">
                        <div class="elementor-spacer-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-shtmnzm elementor-widget elementor-widget-text-editor"
                    data-id="shtmnzm"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>Panie Rejestratorki :</h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-jrdyey7 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="jrdyey7"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Barbara Malesa</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Paulina Kur</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-00b1kpz elementor-widget elementor-widget-spacer"
                    data-id="00b1kpz"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-spacer">
                        <div class="elementor-spacer-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-e6csywd elementor-widget elementor-widget-text-editor"
                    data-id="e6csywd"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3 style="float: left">Pani Optyk</h3>
                      <div>
                        <span style="font-size: large"> K. Strzakłowiec</span>
                      </div>
                      <div style="clear: both"></div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-8e1kdhq elementor-widget elementor-widget-spacer"
                    data-id="8e1kdhq"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="elementor-spacer">
                        <div class="elementor-spacer-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-yczhwq4 elementor-widget elementor-widget-text-editor"
                    data-id="yczhwq4"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>Pracownicy salonu optycznego :</h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-k0zyacl elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="k0zyacl"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Edyta Biaduń</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Aleksandra Mikołajczuk</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Ewelina Soszyńska</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Katarzyna Strzalińska</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Kamila Wereda</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >Żaneta Wyszomierska</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </article>
  </div>

  <Sidebar />
</template>
<style scoped></style>
<script>
console.log("Zespol is loaded");
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>
<style></style>
