<template>
  <div class="art-layout-wrapper">
    <div class="art-content-layout">
      <div class="art-content-layout-row">
        <div class="art-layout-cell art-content">
          <article
            id="post-600"
            class="art-post art-article post-600 page type-page status-publish hentry"
          >
            <h1 class="art-postheader">PACHYMETRIA</h1>
            <div class="art-postcontent clearfix">
              <div
                data-elementor-type="wp-post"
                data-elementor-id="600"
                class="elementor elementor-600"
              >
                <section
                  class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-m80fov8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="m80fov8"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0wsrsmd"
                      data-id="0wsrsmd"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-c5cmxor elementor-widget elementor-widget-heading"
                          data-id="c5cmxor"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h1
                              class="elementor-heading-title elementor-size-default"
                            >
                              Pachymetria
                            </h1>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-vva7y8d elementor-widget elementor-widget-heading"
                          data-id="vva7y8d"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                              Badanie na jaskrę
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-ot1j53y elementor-widget elementor-widget-text-editor"
                          data-id="ot1j53y"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>&nbsp;</p>
                            <h3 >
                              Pachymetria jest to pomiar grubości rogówki
                              niezbędny do określenia rzeczywistego ciśnienia<br>
                              wewnątrzgałkowego. Pachymetria jest
                              obowiązkowym badaniem u chorych
                              na&nbsp;jaskrę.
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-sfp6mpy"
                      data-id="sfp6mpy"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <section
                          class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-ox2ekfc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="ox2ekfc"
                          data-element_type="section"
                        >
                          <div
                            class="elementor-container elementor-column-gap-default"
                          >
                            <div
                              class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77ogjtr"
                              data-id="77ogjtr"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  class="elementor-element elementor-element-qozqiw7 elementor-widget elementor-widget-image"
                                  data-id="qozqiw7"
                                  data-element_type="widget"
                                  data-widget_type="image.default"
                                >
                                  <div class="elementor-widget-container">
                                    <img
                                      decoding="async"
                                      width="640"
                                      height="156"
                                      src="https://okulistyka.siedlce.pl/wp-content/uploads/2020/01/eye-309166_640-300x156.png"
                                      class="attachment-medium size-medium wp-image-1262 lazyloaded"
                                      alt=""
                                      sizes="(max-width: 300px) 100vw, 300px"
                                      srcset="
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2020/01/eye-309166_640-300x156.png 300w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2020/01/eye-309166_640.png         640w
                                      "
                                      data-ll-status="loaded"
                                    /><noscript
                                      ><img
                                        decoding="async"
                                        width="300"
                                        height="156"
                                        src="https://okulistyka.siedlce.pl/wp-content/uploads/2020/01/eye-309166_640-300x156.png"
                                        class="attachment-medium size-medium wp-image-1262"
                                        alt=""
                                        srcset="
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2020/01/eye-309166_640-300x156.png 300w,
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2020/01/eye-309166_640.png         640w
                                        "
                                        sizes="(max-width: 300px) 100vw, 300px"
                                    /></noscript>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-h7u3ege elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="h7u3ege"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ysm6a7l"
                      data-id="ysm6a7l"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap"></div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </article>
        </div>
        <Sidebar />
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>

<style></style>
