<template>
  <div class="art-layout-cell art-sidebar1">
    <div id="text-1" class="art-block widget widget_text clearfix">
      <div class="art-blockheader">
        <div class="t">Zespół</div>
      </div>
      <div class="art-blockcontent">
        <div class="textwidget">
          <p style="text-align: center">
            <img
              alt=""
              height="160"
              src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/04/shutterstock_15714391.jpg"
              width="120"
              class="lazyloaded"
              data-ll-status="loaded"
            /><noscript
              ><img
                alt=""
                height="160"
                src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/04/shutterstock_15714391.jpg"
                width="120"
                class=""
            /></noscript>
          </p>
          <p style="text-align: center"><br /></p>
          <p align="left">
            Dla wszystkich ludzi pracujących w naszej Przychodni najważniejsze
            jest profesjonalne podejście do pacjenta.
          </p>
          <br /><br />
          <router-link to="/zespol" @click="$store.commit('handleReset')" class="art-button" 
            >Czytaj dalej...</router-link
          >

          <p></p>
        </div>
      </div>
    </div>
    <div id="vmenuwidget-1" class="art-vmenublock clearfix">
      <div class="art-vmenublockheader">
        <div class="t">Na skróty</div>
      </div>
      <div class="art-vmenublockcontent">
        <ul class="art-vmenu">
          <li>
            <router-link
              to="/fdtmatrix"
              @click="$store.commit('handleClick1')"
              >FDT-MATRIX</router-link
            >
          </li>
          <li class="menu-item-622">
            <router-link
              to="/polewidzenia"
              @click="$store.commit('handleClick1')"
              >POLE WIDZENIA</router-link
            >
          </li>
          <li class="menu-item-624">
            <router-link
              to="/hrt"
              @click="$store.commit('handleClick1')"
              >HRT</router-link
            >
          </li>
          <li class="menu-item-621">
            <router-link
              to="/cennik"
              @click="$store.commit('handleReset')"
              >CENY BADAŃ</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    // return {
    //   isActive: Array(),
    //   activeNumber: 0,
    // };
  },
  methods: {
    
  },
};
</script>

<style></style>
