<template>
  <head>
    <link rel="stylesheet" href="./css/post-1303.css" />
    <link rel="stylesheet" href="./css/post-167.css" />
    <link rel="stylesheet" href="./css/post-187.css" />
    <link rel="stylesheet" href="./css/all.min.css" />
    <link rel="stylesheet" href="./css/font-awesome.min.css" />
    <link rel="stylesheet" href="./css/frontend.min.css" />
  </head>
  <div class="art-layout-wrapper">
    <div class="art-content-layout">
      <div class="art-content-layout-row">
        <div class="art-layout-cell art-content">
          <article
            id="post-626"
            class="art-post art-article post-626 page type-page status-publish hentry"
          >
            <h1 class="art-postheader">SYNOPTOFOR</h1>
            <div class="art-postcontent clearfix">
              <div
                data-elementor-type="wp-post"
                data-elementor-id="626"
                class="elementor elementor-626"
              >
                <section
                  class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-h7u3ege elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="h7u3ege"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ysm6a7l"
                      data-id="ysm6a7l"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap"></div>
                    </div>
                  </div>
                </section>
                <section
                  class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-m80fov8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="m80fov8"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0wsrsmd"
                      data-id="0wsrsmd"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-c5cmxor elementor-widget elementor-widget-heading"
                          data-id="c5cmxor"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h1
                              class="elementor-heading-title elementor-size-default"
                            >
                              Synoptofor
                            </h1>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-vva7y8d elementor-widget elementor-widget-heading"
                          data-id="vva7y8d"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                              Okulistyka dla dzieci
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-ot1j53y elementor-widget elementor-widget-text-editor"
                          data-id="ot1j53y"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <h3>
                              <p align="justify">
                                Synoptofor jest testem diagnostyczym należącym
                                do&nbsp;podstawowych w
                                <strong>okulistyce dziecięcej.</strong>&nbsp;
                                Służy do&nbsp;badania czy dziecko ma zeza.
                                Okulista za pomocą synoptoforu dokonuje oceny:
                              </p>
                            </h3>
                            <p>&nbsp;</p>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-qxc4kpx elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                          data-id="qxc4kpx"
                          data-element_type="widget"
                          data-widget_type="icon-list.default"
                        >
                          <div class="elementor-widget-container">
                            <ul class="elementor-icon-list-items">
                              <li class="elementor-icon-list-item" style="margin-top:-20px;">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right" style="margin-top:-20px;"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  ><b> obuocznej percepcji</b> - zdolności
                                  widzenia dwóch obrazów powstających na
                                  siatkówkach oczu</span
                                >
                              </li>
                              <li class="elementor-icon-list-item" style="margin-top:10px;">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right" style="margin-top:-23px;"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  ><b> fuzji</b> - łączenia w mózgu dwóch
                                  obrazów z obu oczu w jeden</span
                                >
                              </li>
                              <li class="elementor-icon-list-item" style="margin-top:10px;">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-dot-circle-o" style="margin-top:-35px;"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  ><b> stereopsji</b> - inaczej widzenia
                                  przestrzennego możliwego dzięki centralizacji
                                  dwóch obrazów widzianych sobno przez każde
                                  oko.</span
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-sfp6mpy"
                      data-id="sfp6mpy"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <section
                          class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-ox2ekfc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="ox2ekfc"
                          data-element_type="section"
                        >
                          <div
                            class="elementor-container elementor-column-gap-default"
                          >
                            <div
                              class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77ogjtr"
                              data-id="77ogjtr"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  class="elementor-element elementor-element-qozqiw7 elementor-widget elementor-widget-image"
                                  data-id="qozqiw7"
                                  data-element_type="widget"
                                  data-widget_type="image.default"
                                >
                                  <div class="elementor-widget-container">
                                    <img
                                      fetchpriority="high"
                                      decoding="async"
                                      width="646"
                                      height="621"
                                      src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_.jpg"
                                      class="attachment-full size-full wp-image-803 lazyloaded"
                                      alt=""
                                      sizes="(max-width: 646px) 100vw, 646px"
                                      srcset="
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_.jpg         646w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_-300x288.jpg 300w
                                      "
                                      data-ll-status="loaded"
                                    /><noscript
                                      ><img
                                        fetchpriority="high"
                                        decoding="async"
                                        width="646"
                                        height="621"
                                        src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_.jpg"
                                        class="attachment-full size-full wp-image-803"
                                        alt=""
                                        srcset="
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_.jpg         646w,
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_-300x288.jpg 300w
                                        "
                                        sizes="(max-width: 646px) 100vw, 646px"
                                    /></noscript>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </article>
        </div>
        <Sidebar/>
        
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default {
  components:{
    Sidebar
  }
};
</script>

<style></style>
