<template>
    <head>
        <link rel="stylesheet" href="./css/style.css" />
    </head>
  <div class="art-content-layout">
    <div class="art-content-layout-row">
      <div class="art-layout-cell art-content">
        <article
          id="post-218"
          class="art-post art-article post-218 page type-page status-publish hentry"
        >
          <h1 class="art-postheader">KOMERCYJNE</h1>
          <div class="art-postcontent clearfix">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="218"
              class="elementor elementor-218"
            >
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-la2lblp elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="la2lblp"
                data-element_type="section"
              >
                <table class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a81b7zv"
                    data-id="a81b7zv"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-ns0bpk5 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="ns0bpk5"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator"> </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-x04z54t elementor-widget elementor-widget-heading"
                        data-id="x04z54t"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h1
                            class="elementor-heading-title elementor-size-default"
                          >
                            <center>
                              Stawki za udostępnianie dokumentacji medycznej od
                              1.03.2020
                            </center>
                          </h1>
                        </div>
                      </div>
                      <!-- <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in users" :key="user.id">
                                <td>{{user.firstName}} {{user.lastName}}</td>
                                <td>{{user.email}}</td>
                                <td>{{user.role}}</td>
                            </tr>
                        </tbody>
                    </table> -->
                  
                    <table border="0" style="width: 100%">
                        <thead>
                            <tr>
                                <th>Nazwa</th>
                                <th>Cena</th>
                            </tr>
                        </thead>
                       
                        <tr class="tb" v-for="cena in cennik" :key="cena.id"
                          style="size: 27px; border: 1px solid white !important; "  >
                          <td class="art-postcontent" style="width: 877px; td:hover { background-color: #8b0000!important;  }">
                            <h3 >{{ cena.nazwa }}
                            <!-- <div style="float: right">150 zł</div> -->
                        </h3>
                          </td>
                          <td ><h3 style="text-align: center !important;">{{cena.cena}} zł</h3></td>
                          <!----><br /><br />
                        </tr>
                        
                    </table>

                     
                      
                    </div>
                  </div>
                </table>
              </section>
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-2947ea8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="2947ea8"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b463d81"
                    data-id="b463d81"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-c9241ea elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="c9241ea"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator"> </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-192aa45 elementor-widget elementor-widget-heading"
                        data-id="192aa45"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h1
                            class="elementor-heading-title elementor-size-default"
                          >
                            <center>Konsultacja okulistyczna</center>
                          </h1>
                        </div>
                      </div>
                      <table border="0" style="width: 100%">
                        <thead>
                            <tr>
                                <th>Nazwa</th>
                                <th>Cena</th>
                            </tr>
                        </thead>
                       
                        <tr class="tb" v-for="konsultacja in konsultacje" :key="konsultacja.id"
                          style="size: 27px; border: 1px solid white !important"  >
                          <td class="art-postcontent" style="width: 877px;">
                            <h3 >{{ konsultacja.nazwa }}
                            <!-- <div style="float: right">150 zł</div> -->
                        </h3>
                          </td>
                          <td><h3 style="text-align: center !important;">{{konsultacja.cena}} zł</h3></td>
                          <!----><br /><br />
                        </tr>
                        
                    </table>
                     
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>

       <Sidebar/>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import Sidebar from "@/components/Sidebar.vue";
export default {
    setup() {
        // make users variable reactive with the ref() function
        const cennik = ref([
            {nazwa: 'Za kopię lub wydruk z systemu informatycznego / 1 strona' , cena: '0,36'},
            {nazwa: 'Za wyciąg lub odpis dokumentacji / 1 strona' , cena: '10,39'},
            {nazwa: 'Za informatyczny nośnik danych' , cena: '2,07'},
            
        ]);
        const konsultacje = ref([
            {nazwa:'Wizyta prywatna',cena:'140'},
            {nazwa:'Wizyta domowa', cena:'150'},
            {nazwa:'Komputerowa pole widzenia', cena:'60'},
            {nazwa:'Badanie kąta zeza (synoptofor)', cena:'30'},
            {nazwa:'Pachymetria', cena:'25'},
            {nazwa:'HRT', cena:'80'},
            {nazwa:'OCT', cena:'150'},
            {nazwa:'USG gałek ocznych', cena:'100'},
            {nazwa:'Zatyczki do punktów łzowych', cena:'450'},
        ]);


        return {
            cennik,
            konsultacje
        };
    },
  components: { Sidebar },
};
</script>

<style></style>
