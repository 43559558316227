<template>
  <div class="art-content-layout">
    <div class="art-content-layout-row">
      <div class="art-layout-cell art-content">
        <article
          id="post-222"
          class="art-post art-article post-222 page type-page status-publish hentry"
        >
          <h1 class="art-postheader">ĆWICZENIA ORTOPTYCZNE</h1>
          <div class="art-postcontent clearfix">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="222"
              class="elementor elementor-222 elementor-bc-flex-widget"
            >
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-l3seuoo elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="l3seuoo"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-yedyyfv"
                    data-id="yedyyfv"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-bszjx5l elementor-widget elementor-widget-image"
                        data-id="bszjx5l"
                        data-element_type="widget"
                        data-widget_type="image.default"
                      >
                        <div class="elementor-widget-container">
                          <img
                            fetchpriority="high"
                            decoding="async"
                            width="768"
                            height="624"
                            src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01217-CopyORTOPTYCZNE_-768x624.jpg"
                            class="attachment-medium_large size-medium_large wp-image-1041 lazyloaded"
                            alt=""
                            sizes="(max-width: 768px) 100vw, 768px"
                            srcset="
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01217-CopyORTOPTYCZNE_-768x624.jpg 768w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01217-CopyORTOPTYCZNE_-300x244.jpg 300w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01217-CopyORTOPTYCZNE_.jpg         940w
                            "
                            data-ll-status="loaded"
                          /><noscript
                            ><img
                              fetchpriority="high"
                              decoding="async"
                              width="768"
                              height="624"
                              src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01217-CopyORTOPTYCZNE_-768x624.jpg"
                              class="attachment-medium_large size-medium_large wp-image-1041"
                              alt=""
                              srcset="
                                https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01217-CopyORTOPTYCZNE_-768x624.jpg 768w,
                                https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01217-CopyORTOPTYCZNE_-300x244.jpg 300w,
                                https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01217-CopyORTOPTYCZNE_.jpg         940w
                              "
                              sizes="(max-width: 768px) 100vw, 768px"
                          /></noscript>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-l1pfhgi elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="l1pfhgi"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dqz1nz0"
                    data-id="dqz1nz0"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-nxjf7l7 elementor-widget elementor-widget-text-editor"
                        data-id="nxjf7l7"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h2>
                            Ćwiczenia ortoptyczne w leczeniu zeza i zaburzeń
                            widzenia &nbsp;obuocznego.
                          </h2>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>
      <Sidebar/>
      <!--  -->
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default {
    components: {
        Sidebar
    }
};
</script>

<style></style>
