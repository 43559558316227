<template>
  <AppHeader />
  <!-- <router-view></router-view> -->
</template>
<script>
import Vue from 'vue';
import AppHeader from "./components/AppHeader.vue";
import ZespolView from "./views/ZespolView.vue";
import KonsultacjaView from "./views/KonsultacjaView.vue";
// Vue.prototype.$myGlobalVariable = 'some value';
export default {
  
  data() {
    return {
      isActive1: false,
      isActive2: false,
      
    };
  },
  components: {
    AppHeader,
    ZespolView,
    KonsultacjaView,
    methods: {
      // handleReset() {
      //   this.isActive1 = false;
      //   this.isActive2 = false;
      // },
      // handleClick1() {
      //   // this.isActive1 = !this.isActive1;
      //   this.isActive1 = true;

      //   // console.log(number);
      //   // console.log(this.activeNumber);
      // },
      // handleClick2() {
      //   this.isActive1 = false;
      //   this.isActive2 = true;
      // },
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  /*color: #ffffff!important;*/
  background: #77bafe;
  background: linear-gradient(top, #9fcffe 0, #4ea6fd 100%) no-repeat;
  background: -webkit-linear-gradient(top, #9fcffe 0, #4ea6fd 100%) no-repeat;
  background: -moz-linear-gradient(top, #9fcffe 0, #4ea6fd 100%) no-repeat;
  background-origin: padding-box;
  background: -o-linear-gradient(top, #9fcffe 0, #4ea6fd 100%) no-repeat;
  background: -ms-linear-gradient(top, #9fcffe 0, #4ea6fd 100%) no-repeat;
  background: linear-gradient(top, #9fcffe 0, #4ea6fd 100%) no-repeat;
  -svg-background: linear-gradient(top, #9fcffe 0, #4ea6fd 100%) no-repeat;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #49a3fd;
  padding: 0 40px;
  margin: 0 auto;
  color: #ffffff;
  text-decoration: none;
}
ul.art-hmenu > li > a.active {
  /*color: #1900ff!important;*/
}
</style>
