<template>
  <transition name="slide-in">
    <div class="carousel-item" v-show="currentSlide === index">
      <img style="height: 460px;" :src="slide" /><div
       style="font-size: 350%; font-weight: bold;
       padding-top:0px;

      "><div style="text-align: center;">{{titles[index]}}</div></div>
    </div>
    
  </transition>
  
</template>

<script>
export default {
  props: ["slide", "currentSlide", "index"],
  data(){
    return {    titles: [
    'Optyka',
    'Okulistyka dziecięca',
    'Nowoczesna aparatura',
    'Kompleksowa diagnostyka',
    'Doświadczone kadra',
    'Wykwalifikowany zespół'
]}

  }
  
};
</script>

<style scoped>
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slide-in-enter-active,
.slide-in-leave-active {
  transition: opacity 0.5s ease;
}
.slide-in-enter-from {
  opacity: 0;
}
.slide-in-leve-to {
  opacity: 0;
}
</style>
