<template>
  <div class="art-content-layout">
    <div class="art-content-layout-row">
      <div class="art-layout-cell art-content">
        <article
          id="post-220"
          class="art-post art-article post-220 page type-page status-publish hentry"
        >
          <h1 class="art-postheader">ĆWICZENIA PLEOPTYCZNE</h1>
          <div class="art-postcontent clearfix">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="220"
              class="elementor elementor-220 elementor-bc-flex-widget"
            >
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-5t9ih9t elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="5t9ih9t"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0ohjmby"
                    data-id="0ohjmby"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-ajct8as elementor-widget elementor-widget-image"
                        data-id="ajct8as"
                        data-element_type="widget"
                        data-widget_type="image.default"
                      >
                        <div class="elementor-widget-container">
                          <img
                            fetchpriority="high"
                            decoding="async"
                            width="646"
                            height="621"
                            src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_.jpg"
                            class="attachment-large size-large wp-image-803 lazyloaded"
                            alt=""
                            sizes="(max-width: 646px) 100vw, 646px"
                            srcset="
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_.jpg         646w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_-300x288.jpg 300w
                            "
                            data-ll-status="loaded"
                          /><noscript
                            ><img
                              fetchpriority="high"
                              decoding="async"
                              width="646"
                              height="621"
                              src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_.jpg"
                              class="attachment-large size-large wp-image-803"
                              alt=""
                              srcset="
                                https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_.jpg         646w,
                                https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01216-Copy_-300x288.jpg 300w
                              "
                              sizes="(max-width: 646px) 100vw, 646px"
                          /></noscript>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-wmabp4k elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="wmabp4k"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-r0p6ry3"
                    data-id="r0p6ry3"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-s1pfmgk elementor-widget elementor-widget-text-editor"
                        data-id="s1pfmgk"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h2>
                            &nbsp; Ćwiczenia pleoptyczne – są stosowane w
                            leczeniu niedowidzenia.
                          </h2>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>
    <Sidebar/>
      
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default { components: { Sidebar } };
</script>

<style></style>
