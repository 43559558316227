<template>
  <head>
    <link rel="stylesheet" href="./css/post-1303.css" />
    <link rel="stylesheet" href="./css/post-167.css" />
    <link rel="stylesheet" href="./css/post-187.css" />
    <link rel="stylesheet" href="./css/all.min.css" />
    <link rel="stylesheet" href="./css/font-awesome.min.css" />
    <link rel="stylesheet" href="./css/frontend.min.css" />
  </head>
  <div class="art-content-layout">
    <div class="art-content-layout-row">
      <div class="art-layout-cell art-content">
        <article
          id="post-923"
          class="art-post art-article post-923 page type-page status-publish hentry"
        >
          <h1 class="art-postheader">
            DOBÓR POMOCY OPTYCZNYCH DLA SŁABO WIDZĄCYCH
          </h1>
          <div class="art-postcontent clearfix">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="923"
              class="elementor elementor-923 elementor-bc-flex-widget"
            >
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-h7u3ege elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="h7u3ege"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ysm6a7l"
                    data-id="ysm6a7l"
                    data-element_type="column"
                  >
                    <div class="elementor-widget-wrap"></div>
                  </div>
                </div>
              </section>
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-m80fov8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="m80fov8"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0wsrsmd"
                    data-id="0wsrsmd"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-c5cmxor elementor-widget elementor-widget-heading"
                        data-id="c5cmxor"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h1
                            class="elementor-heading-title elementor-size-default"
                          >
                            Dobór pomocy dla słabo widzących
                          </h1>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-ot1j53y elementor-widget elementor-widget-text-editor"
                        data-id="ot1j53y"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3 align="justify">
                            Fachowa pomoc przy doborze i sprzedaży pomocy
                            dla osób słabowidzących. Pacjenci, którym korekcja
                            okularowa już nie wystarcza mogą skorzystać<br>z fachowego doboru pomocy optycznych
                            w naszej przychodni. Pomoce dla osób słabo
                            widzących, to różnego rodzaju lupy, monookulary,
                            okulary lupowe, okulary lornetkowe, powiększalniki
                            elektroniczne i inne akcesoria ułatwiające codzienne
                            czynności. Wyżej wymienione pomoce stosowane<br />
                            są głównie u osób ze znacznym upośledzeniem
                            widzenia.
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-bol57vt elementor-widget elementor-widget-text-editor"
                        data-id="bol57vt"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h2>
                            Schorzenia, przy których stosuje się powiększające
                            pomoce:
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-15xx2k3 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="15xx2k3"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >Zwyrodnienie plamki</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >Niedorozwój plamki, achromatopsja</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >Barwnikowe zwyrodnienie siatkówki</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-j3d6n1s elementor-widget elementor-widget-text-editor"
                        data-id="j3d6n1s"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                           Pacjent powinien przynieść ze sobą
                            dokumentację medyczną (jeśli posiada), okulary do
                            bliży i dali, dokument tożsamości i legitymację ZUS
                            (wojskową lub inną).
                          </h3>
                          <h3>
                            Wizyta doboru pomocy dla słabowidzących obejmuje:
                          </h3>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-p3m6mrz elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="p3m6mrz"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >Badanie refrakcji komputerowej</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >Sprawdzenie ostrości do dali i bliży</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >Pomiar okularów używanych przez pacjenta.</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >Prezentacja i dobór pomocy optycznych dla
                                słabowidzących</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >Wypisanie wniosku do NFZ</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-pdc4lsb elementor-widget elementor-widget-text-editor"
                        data-id="pdc4lsb"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                             Po wizycie pacjent otrzymuje wypełniony
                            wniosek zaopatrzenia w środki optyczne, który należy
                            złożyć w&nbsp;regionalnym oddziale NFZ.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-sfp6mpy"
                    data-id="sfp6mpy"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <section
                        class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-ox2ekfc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="ox2ekfc"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77ogjtr"
                            data-id="77ogjtr"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-7u98pnk elementor-widget elementor-widget-image"
                                data-id="7u98pnk"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div class="elementor-widget-container">
                                  <img
                                    fetchpriority="high"
                                    decoding="async"
                                    width="1713"
                                    height="963"
                                    src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy.jpg"
                                    class="attachment-full size-full wp-image-678 lazyloaded"
                                    alt=""
                                    sizes="(max-width: 1713px) 100vw, 1713px"
                                    srcset="
                                      https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy.jpg          1713w,
                                      https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy-300x169.jpg   300w,
                                      https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy-768x432.jpg   768w,
                                      https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy-1024x576.jpg 1024w
                                    "
                                    data-ll-status="loaded"
                                  /><noscript
                                    ><img
                                      fetchpriority="high"
                                      decoding="async"
                                      width="1713"
                                      height="963"
                                      src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy.jpg"
                                      class="attachment-full size-full wp-image-678"
                                      alt=""
                                      srcset="
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy.jpg          1713w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy-300x169.jpg   300w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy-768x432.jpg   768w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01223-Copy-1024x576.jpg 1024w
                                      "
                                      sizes="(max-width: 1713px) 100vw, 1713px"
                                  /></noscript>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>

      <Sidebar/>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default {
    components: {
        Sidebar
    }
};
</script>

<style></style>
