import { createRouter, createWebHistory } from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'
import HomeView from '../views/HomeView.vue'
import ZespolView from '../views/ZespolView'
import KonsultacjaView from '../views/KonsultacjaView'
import PoleWidzeniaView from '../views/PoleWidzeniaView'
import FdtMatrixView from '../views/FdtMatrixView'
import HRTView from '../views/HRTView'
import OCTView from '../views/OCTView'
import PachymetriaView from '../views/PachymetriaView'
import SynoptoforView from '../views/SynoptoforView'
import USGABView from '../views/USGABView'
import SoczewkiView from '../views/SoczewkiView'
import BadanieNurzencaView from '../views/BadanieNurzencaView'
import ZespolSuchegoOkaView from '../views/ZespolSuchegoOkaView'
import ProfilaktykaView from '../views/ProfilaktykaView'
import DoborPomocy from '../views/DoborPomocyView'
import OptykaView from '../views/OptykaView'
import CennikView from '../views/CennikView'
import PleoptyczneView from '../views/PleoptyczneView'
import OrtoptyczneView from '../views/OrtoptyczneView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/zespol',
    name: 'Zespol',
    component: ZespolView
  },
  {
    path: '/konsultacja',
    name: 'Konsultacja',
    component: KonsultacjaView
  },
  {
    path: '/polewidzenia',
    name: 'polewidzenia',
    component: PoleWidzeniaView
  },
  {
    path: '/fdtmatrix',
    name: 'fdtmatrix',
    component: FdtMatrixView
  },
  {
    path: '/hrt',
    name: 'hrt',
    component: HRTView
  },
  {
    path: '/oct',
    name: 'oct',
    component: OCTView
  },
  {
    path: '/pachymetria',
    name: 'pachymetria',
    component: PachymetriaView
  },
  {
    path: '/synoptofor',
    name: 'synoptofor',
    component: SynoptoforView
  },
  {
    path: '/usgab',
    name: 'usgab',
    component: USGABView
  },
  {
    path: '/soczewkikontaktowe',
    name: 'soczewkikontaktowe',
    component: SoczewkiView
  },
  {
    path: '/badanienurzenca',
    name: 'badanienurzenca',
    component: BadanieNurzencaView
  },
  {
    path: '/zespolsuchegooka',
    name: 'zespolsuchegooka',
    component: ZespolSuchegoOkaView
  },
  {
    path: '/profilaktyka',
    name: 'profilaktyka',
    component: ProfilaktykaView
  },
  {
    path: '/doborpomocy',
    name: 'doborpomocy',
    component: DoborPomocy
  },
  ,
  {
    path: '/optyka',
    name: 'Optyka',
    component: OptykaView
  },
  {
    path: '/cennik',
    name: 'Cennik',
    component: CennikView
  },
  {
    path: '/pleoptyczne',
    name: 'Pleoptyczne',
    component: PleoptyczneView
  },
  {
    path: '/ortoptyczne',
    name: 'Ortoptyczne',
    component: OrtoptyczneView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
