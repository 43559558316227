<template>
  <div class="art-content-layout">
    <div class="art-content-layout-row">
      <div class="art-layout-cell art-content">
        <article
          id="post-1053"
          class="art-post art-article post-1053 page type-page status-publish hentry"
        >
          <h1 class="art-postheader">OPTYKA</h1>
          <div class="art-postcontent clearfix">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="1053"
              class="elementor elementor-1053"
            >
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-1y3tw5j elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="1y3tw5j"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-j2rzptn"
                    data-id="j2rzptn"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-88e463f elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="88e463f"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                      <hr class="gradient">
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator"> </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-1z3xvkh elementor-widget elementor-widget-heading"
                        data-id="1z3xvkh"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                      <!-- <hr class="gradient"> -->
                        <div class="elementor-widget-container">
                          <h2
                            class="elementor-heading-title elementor-size-default"
                          >
                            <center>Wykonanie okularów korekcyjnych</center>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-v5bvg2s elementor-widget elementor-widget-text-editor"
                        data-id="v5bvg2s"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                      <!-- <hr class="gradient"> -->
                        <div class="elementor-widget-container">
                          <h3 align="JUSTIFY">
                            &nbsp;Podstawową część naszej oferty, stanowią
                            usługi w zakresie wyrobu okularów korekcyjnych.
                            Wykonujemy wiele ich typów — progresywne, jedno i
                            dwuogniskowe, biurowe, barwione, fotochromowe, itp.
                          </h3>
                          <h3 align="JUSTIFY">
                            Posiadamy wiele typów opraw do każdego rodzaju
                            okularów, klasyczne lub nowoczesne wzory dla
                            dorosłych i dla dzieci, na każdy gust, na każdą
                            kieszeń. Wszystkim klientom zapewniamy fachowe
                            doradztwo w celu odpowiedniego doboru opraw, w
                            zależności od przeznaczenia okularów oraz
                            indywidualnych cech i upodobań.
                          </h3>
                          <h3 align="justify">
                            Do wybranych przez Państwa opraw proponujemy
                            organiczne lub mineralne soczewki okularowe
                            renomowanych producentów krajowych i zagranicznych –
                            <strong
                              ><em
                                >HOYA, JAI KUBO, JZO, ESSILOR, SZAJNA,
                                S-COMFORT</em
                              ></strong
                            >. Dobór odbywa się indywidualnie z czynnym udziałem
                            klienta i z uwzględnieniem jego preferencji. Zawsze
                            mogą Państwo liczyć na fachowe doradztwo w tym
                            zakresie – pomożemy wybrać najlepsze soczewki.
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-fzf0rxm elementor-widget elementor-widget-spacer"
                        data-id="fzf0rxm"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                      <!-- <hr class="gradient"> -->
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-89ae238 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="89ae238"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                      <hr class="gradient">
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator"> </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-kurnwzl elementor-widget elementor-widget-heading"
                        data-id="kurnwzl"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h2
                            class="elementor-heading-title elementor-size-default"
                          >
                            <center>
                              Wyróżnij się w najmodniejszych okularach znanych I
                              cenionych marek!
                            </center>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-oclnmgw elementor-widget elementor-widget-text-editor"
                        data-id="oclnmgw"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3 align="justify">
                             W ofercie naszego salonu znajdą Państwo
                            szeroki wybór najnowszych modeli ekskluzywnych marek
                            okularowych z&nbsp;limitowanych serii, dostępnych od
                            ręki. Kolekcja jest ciągle aktualizowana, a starsze
                            modele dostępne są w bardzo atrakcyjnych cenach.
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-wx0nayo elementor-widget elementor-widget-heading"
                        data-id="wx0nayo"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h3
                            class="elementor-heading-title elementor-size-medium"
                          >
                            <center>
                              RAY BAN, EMPORIO ARMANI, VERSACE, VOGUE
                            </center>
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-yaompvz elementor-widget elementor-widget-spacer"
                        data-id="yaompvz"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                      <hr class="gradient">
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-il43gwb elementor-widget elementor-widget-heading"
                        data-id="il43gwb"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h2
                            class="elementor-heading-title elementor-size-default"
                          >
                            <center>
                              Dbamy również o naszych najmniejszych klientów, w
                              ofercie oprawy firmy:
                            </center>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-6q5q1xd elementor-widget elementor-widget-heading"
                        data-id="6q5q1xd"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h3
                            class="elementor-heading-title elementor-size-default"
                          >
                          <br><br>
                            <center>
                              AJG Office, Fisher Price i wiele innych firm.
                            </center>
                          </h3>
                        </div>
                      </div>
                      <!-- <div
                        class="elementor-element elementor-element-pp48ibe elementor-widget elementor-widget-spacer"
                        data-id="pp48ibe"
                        data-element_type="widget"
                        data-widget_type="spacer.default"  >
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div> -->
                      <div
                        class="elementor-element elementor-element-7fdd041 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="7fdd041"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                      <hr class="gradient">
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator"> </span>
                          </div>
                        </div>
                      </div>
                      
                      <div
                        class="elementor-element elementor-element-bwhjxzq elementor-widget elementor-widget-heading"
                        data-id="bwhjxzq"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <!-- <hr class="gradient"> -->
                          <h2
                            class="elementor-heading-title elementor-size-default"
                          >
                          
                            <center>
                              Korekcyjne okulary przeciwsłoneczne i okulary
                              specjalistyczne
                            </center>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-p1bx7ry elementor-widget elementor-widget-text-editor"
                        data-id="p1bx7ry"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            <p align="justify">
                              &nbsp; W naszym salonie znajdą Państwo okulary
                              przeciwsłoneczne z najwyższej jakości soczewkami
                              ochronnymi, które posiadają filtry UV 100%, oraz
                              polaryzację firmy: SOLANO, BELUTTI, TONNY,
                              możliwość sprawdzenia natężenia filtru oraz
                              polaryzacji.
                            </p>
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-yggzood elementor-widget elementor-widget-text-editor"
                        data-id="yggzood"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            <p align="justify">
                              Wykonujemy korekcyjne okulary przeciwsłoneczne z
                              wykorzystaniem opraw tradycyjnych lub
                              przeciwsłonecznych. Montażu dokonujemy również do
                              opraw dostarczonych przez klienta. Korekcyjne
                              soczewki przeciwsłoneczne oferujemy w wielu
                              kolorach i z różną intensywnością barwienia, z
                              powloką AR lub lustrzaną.
                            </p>
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-d3q81cb elementor-widget elementor-widget-text-editor"
                        data-id="d3q81cb"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            <p align="justify">
                              Oferujemy specjalne soczewki dla kierowców typu
                              Drive Wear oraz Blue Blocker oraz soczewki
                              polaryzacyjne poprawiające komfort i
                              bezpieczeństwo jazdy.
                            </p>
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-3xai2qc elementor-widget elementor-widget-text-editor"
                        data-id="3xai2qc"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            <p align="justify">
                              Proponujemy również soczewki z powłoką BLUE
                              PROTECT, LED CONTROL: antyrefleksyjna powłoka
                              odbija światło niebieskie 5 razy lepiej niz
                              tradycyjne powłoki antyrefleksyjne, powłoka ta
                              polecana jest szczególnie dla osób, które przez
                              większość czasu pracują na nowoczesnych
                              urządzeniach cyfrowych z ekranami LED/LCD.
                            </p>
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-0ql7li7 elementor-widget elementor-widget-text-editor"
                        data-id="0ql7li7"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            <p align="justify">
                              Na życzenie sprowadzamy okulary specjalistyczne z
                              możliwością montażu korekcji: maski do nurkowania,
                              okularki pływackie, gogle narciarskie,
                              motocrossowe oraz okulary rowerowe i do biegania.
                              Soczewki korekcyjne oprawiamy również do okularów
                              spawalniczych i ochronnych
                            </p>
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-0807j4a elementor-widget elementor-widget-spacer"
                        data-id="0807j4a"
                        data-element_type="widget"
                        data-widget_type="spacer.default"
                      >
                        <div class="elementor-widget-container">
                          <div class="elementor-spacer">
                            <div class="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-c6a01dc elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="c6a01dc"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                      <hr class="gradient">
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator"> </span>
                          </div>
                        </div>
                      </div>
                      
                      <div
                        class="elementor-element elementor-element-p5fp3tx elementor-widget elementor-widget-heading"
                        data-id="p5fp3tx"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                      <!-- <hr class="gradient"> -->
                        <div class="elementor-widget-container">
                          
                          <h2
                            class="elementor-heading-title elementor-size-default"
                          >
                            <center>
                              Wymiana soczewek w okularach, wymiana oprawy, itp.
                            </center>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-x9ixx5f elementor-widget elementor-widget-text-editor"
                        data-id="x9ixx5f"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            <p align="justify">
                              Jeżeli nie zamierzają Państwo zmieniać posiadanej
                              oprawy a np. zaszła potrzeba zmiany mocy okularów,
                              oferujemy wymianę samych soczewek w Państwa
                              obecnej oprawie.
                            </p>
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-bctc9g8 elementor-widget elementor-widget-text-editor"
                        data-id="bctc9g8"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            Oferujemy montaż soczewek do nowych opraw
                            przyniesionych przez klienta. Jednocześnie
                            wykonujemy usługi montażu soczewek dostarczonych nam
                            przez klienta do naszej lub powierzonej nam oprawy.
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-lfuqj2b elementor-widget elementor-widget-text-editor"
                        data-id="lfuqj2b"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            Jeśli w posiadanych przez Państwa okularach
                            uszkodzeniu uległa oprawa a soczewki nadają się do
                            ponownego montażu, dopasujemy do nich nową oprawę.
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-314d913 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="314d913"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                      <hr class="gradient">
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator"> </span>
                          </div>
                        </div>
                      </div>
                      
                      <div
                        class="elementor-element elementor-element-ao443mz elementor-widget elementor-widget-heading"
                        data-id="ao443mz"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                      <!-- <hr class="gradient"> -->
                        <div class="elementor-widget-container">
                          <h2
                            class="elementor-heading-title elementor-size-default"
                          >
                            <center>Serwis i naprawa okularów</center>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-euiscna elementor-widget elementor-widget-text-editor"
                        data-id="euiscna"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                      <!-- <hr class="gradient"> -->
                        <div class="elementor-widget-container">
                          <h3>
                            <p align="justify">
                              Oferujemy kompleksowy serwis, okresowy przegląd a
                              także mycie okularów w urządzeniu wykorzystującym
                              ultradźwięki. Podejmujemy się wszelkiego rodzaju
                              napraw okularów, tych drobnych (śrubki, nanośniki,
                              itp.) i tych poważniejszych w tym np. prostujemy
                              zdeformowane okulary, jeśli jest taka możliwość
                              reperujemy złamane oprawy. Naprawiamy proste
                              przyrządy optyczne.
                            </p>
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-689113a elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="689113a"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                      <hr class="gradient">
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator"> </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-8b3ur2n elementor-widget elementor-widget-heading"
                        data-id="8b3ur2n"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h2
                            class="elementor-heading-title elementor-size-default"
                          >
                            <center>
                              Soczewki kontaktowe (Armani, S Comfort, ALCON,
                              JOHNSON &amp; JOHNSON )
                            </center>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-ymogzra elementor-widget elementor-widget-text-editor"
                        data-id="ymogzra"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            Nasz salon oferuje Państwu profesjonalny dobór
                            soczewek kontaktowych, przez wyspecjalizowany zespół
                            lekarzy.
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-cy1ofve elementor-widget elementor-widget-text-editor"
                        data-id="cy1ofve"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            Posiadamy w ciągłej sprzedaży miesięczne soczewki
                            kontaktowe firmy ALCON,Posiadamy w ciągłej sprzedaży
                            miesięczne soczewki kontaktowe firmy
                            ALCON,JOHNSON&amp;JOHNSON. Magazyn obejmuje moce od
                            <em><strong>-0,25</strong></em> do
                            <em>-6,00</em> Dioptrii. Na zamówienie dostępne są
                            także soczewki o innej mocy, cylindryczne lub
                            zmieniające kolor oczu firmy ALCON. Na Państwa
                            życzenie zamawiamy też soczewki kontaktowe innych
                            producentów Nasi klienci objęci są również
                            programami umożliwiającymi korzystanie z dużych
                            rabatów.
                            <p></p>
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-pc5llur elementor-widget elementor-widget-text-editor"
                        data-id="pc5llur"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            W ofercie posiadamy również płyny do pielęgnacji
                            soczewek kontaktowych.
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-565e108 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="565e108"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                      <hr class="gradient">
                        <div class="elementor-widget-container">
                          <div class="elementor-divider">
                            <span class="elementor-divider-separator"> </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-vfp3rqm elementor-widget elementor-widget-heading"
                        data-id="vfp3rqm"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h2
                            class="elementor-heading-title elementor-size-default"
                          >
                            <center>Galanteria optyczna</center>
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-wingdai elementor-widget elementor-widget-text-editor"
                        data-id="wingdai"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3>
                            <p align="justify">
                              W ofercie posiadamy bogaty asortyment galanterii
                              optycznej — okulary i nakładki przeciwsłoneczne,
                              futerały okularowe, lupy, zawieszki, obturatory,
                              stopery, ściereczki, płyny pielęgnacyjne do
                              soczewek okularowych, itp.
                            </p>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>
<Sidebar/>
      
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default {
    components: {
        Sidebar
    }
};
</script>

<style></style>
