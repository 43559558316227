<template>
  <head>
    <link rel="stylesheet" href="./css/post-1303.css" />
    <link rel="stylesheet" href="./css/post-167.css" />
    <link rel="stylesheet" href="./css/post-187.css" />
    <link rel="stylesheet" href="./css/all.min.css" />
    <link rel="stylesheet" href="./css/font-awesome.min.css" />
    <link rel="stylesheet" href="./css/frontend.min.css" />
  </head>
  <div class="art-content-layout">
    <div class="art-content-layout-row">
      <div class="art-layout-cell art-content" style="height: 552px">
        <article
          id="post-908"
          class="art-post art-article post-908 page type-page status-publish hentry"
        >
          <h1 class="art-postheader">PROFILAKTYKA</h1>
          <div class="art-postcontent clearfix">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="908"
              class="elementor elementor-908 elementor-bc-flex-widget"
            >
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-h7u3ege elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="h7u3ege"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ysm6a7l"
                    data-id="ysm6a7l"
                    data-element_type="column"
                  >
                    <div class="elementor-widget-wrap"></div>
                  </div>
                </div>
              </section>
              <section
                class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-m80fov8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="m80fov8"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0wsrsmd"
                    data-id="0wsrsmd"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <div
                        class="elementor-element elementor-element-c5cmxor elementor-widget elementor-widget-heading"
                        data-id="c5cmxor"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div class="elementor-widget-container">
                          <h1
                            class="elementor-heading-title elementor-size-default"
                          >
                            Profilaktyka okulistyczna
                          </h1>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-ot1j53y elementor-widget elementor-widget-text-editor"
                        data-id="ot1j53y"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h3 align="justify">
                            Poprzez pogłębioną profilaktykę udaje się we
                            wczesnym stadium zdiagnozować chorobę narządu wzroku
                            lub wadę i szybko wdrożyć odpowiednie
                            postępowanie.&nbsp;
                          </h3>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-bol57vt elementor-widget elementor-widget-text-editor"
                        data-id="bol57vt"
                        data-element_type="widget"
                        data-widget_type="text-editor.default"
                      >
                        <div class="elementor-widget-container">
                          <h2>Nasza przychodnia wykonuje:</h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-15xx2k3 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="15xx2k3"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >dobranie okularów do czytania i prowadzenia
                                samochodu</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >okulistyczne badania kierowców</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >dobranie okularów do pracy na komputerze</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >wydawanie zaświadczeń lekarskich dla pracodawcy
                                w celu refundacji</span
                              >
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <i
                                  class="fa fa-arrow-circle-o-right"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="elementor-icon-list-text"
                                >badanie okresowe dla zakładu pracy</span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-sfp6mpy"
                    data-id="sfp6mpy"
                    data-element_type="column"
                  >
                    <div
                      class="elementor-widget-wrap elementor-element-populated"
                    >
                      <section
                        class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-ox2ekfc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="ox2ekfc"
                        data-element_type="section"
                      >
                        <div
                          class="elementor-container elementor-column-gap-default"
                        >
                          <div
                            class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77ogjtr"
                            data-id="77ogjtr"
                            data-element_type="column"
                          >
                            <div
                              class="elementor-widget-wrap elementor-element-populated"
                            >
                              <div
                                class="elementor-element elementor-element-7u98pnk elementor-widget elementor-widget-image"
                                data-id="7u98pnk"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div class="elementor-widget-container">
                                  <img
                                    decoding="async"
                                    src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC012641.jpg"
                                    title="DSC01264[1]"
                                    alt="DSC01264[1]"
                                    loading="lazy"
                                    class="lazyloaded"
                                    data-ll-status="loaded"
                                  /><noscript
                                    ><img
                                      decoding="async"
                                      src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC012641.jpg"
                                      title="DSC01264[1]"
                                      alt="DSC01264[1]"
                                      loading="lazy"
                                  /></noscript>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>
<Sidebar/>
      <!--  -->
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default {
    components:{
        Sidebar
    }
};
</script>

<style></style>
