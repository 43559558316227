<template>
  <head>
    <link rel="stylesheet" href="./css/post-1303.css" />
    <link rel="stylesheet" href="./css/font-awesome.min.css" />
  </head>
  <div class="art-layout-wrapper">
    <div class="art-content-layout">
      <div class="art-content-layout-row">
        <div class="art-layout-cell art-content">
          <article
            id="post-187"
            class="art-post art-article post-187 page type-page status-publish hentry"
          >
            <h1 class="art-postheader">OCT</h1>
            <div class="art-postcontent clearfix">
              <div
                data-elementor-type="wp-post"
                data-elementor-id="187"
                class="elementor elementor-187 elementor-bc-flex-widget"
              >
                <section
                  class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-xgdlcgp elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="xgdlcgp"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-xwqccxh"
                      data-id="xwqccxh"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-3xatj5x elementor-widget elementor-widget-heading"
                          data-id="3xatj5x"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h1
                              class="elementor-heading-title elementor-size-default"
                            >
                              Optyczna koherentna tomografia
                            </h1>
                          </div>
                        </div>
                        <section
                          class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-8c8mgek elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="8c8mgek"
                          data-element_type="section"
                        >
                          <div
                            class="elementor-container elementor-column-gap-default"
                          >
                            <div
                              class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-nb1t0lu"
                              data-id="nb1t0lu"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  class="elementor-element elementor-element-8in9545 elementor-widget elementor-widget-image"
                                  data-id="8in9545"
                                  data-element_type="widget"
                                  data-widget_type="image.default"
                                >
                                  <div class="elementor-widget-container">
                                    <img
                                      fetchpriority="high"
                                      decoding="async"
                                      width="374"
                                      height="162"
                                      src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/tomogram_oct_okulistyka01.jpg"
                                      class="attachment-large size-large wp-image-786 lazyloaded"
                                      alt=""
                                      sizes="(max-width: 374px) 100vw, 374px"
                                      srcset="
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/tomogram_oct_okulistyka01.jpg         374w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/tomogram_oct_okulistyka01-300x130.jpg 300w
                                      "
                                      data-ll-status="loaded"
                                    /><noscript
                                      ><img
                                        fetchpriority="high"
                                        decoding="async"
                                        width="374"
                                        height="162"
                                        src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/tomogram_oct_okulistyka01.jpg"
                                        class="attachment-large size-large wp-image-786"
                                        alt=""
                                        srcset="
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/tomogram_oct_okulistyka01.jpg         374w,
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/tomogram_oct_okulistyka01-300x130.jpg 300w
                                        "
                                        sizes="(max-width: 374px) 100vw, 374px"
                                    /></noscript>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-rg28arl"
                              data-id="rg28arl"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  class="elementor-element elementor-element-33ocwlh elementor-widget elementor-widget-image"
                                  data-id="33ocwlh"
                                  data-element_type="widget"
                                  data-widget_type="image.default"
                                >
                                  <div class="elementor-widget-container">
                                    <img
                                      decoding="async"
                                      width="1009"
                                      height="1024"
                                      src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1-1009x1024.jpg"
                                      class="attachment-large size-large wp-image-787 lazyloaded"
                                      alt=""
                                      sizes="(max-width: 1009px) 100vw, 1009px"
                                      srcset="
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1-1009x1024.jpg 1009w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1-296x300.jpg    296w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1-768x779.jpg    768w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1.jpg           1597w
                                      "
                                      data-ll-status="loaded"
                                    /><noscript
                                      ><img
                                        decoding="async"
                                        width="1009"
                                        height="1024"
                                        src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1-1009x1024.jpg"
                                        class="attachment-large size-large wp-image-787"
                                        alt=""
                                        srcset="
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1-1009x1024.jpg 1009w,
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1-296x300.jpg    296w,
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1-768x779.jpg    768w,
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/oct_okulistyka06-1.jpg           1597w
                                        "
                                        sizes="(max-width: 1009px) 100vw, 1009px"
                                    /></noscript>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ww9qkzt"
                              data-id="ww9qkzt"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  class="elementor-element elementor-element-k8qgkha elementor-widget elementor-widget-image"
                                  data-id="k8qgkha"
                                  data-element_type="widget"
                                  data-widget_type="image.default"
                                >
                                  <div class="elementor-widget-container">
                                    <img
                                      decoding="async"
                                      width="523"
                                      height="327"
                                      src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/pachymetria_04okulistyka06.jpg"
                                      class="attachment-large size-large wp-image-768 lazyloaded"
                                      alt=""
                                      sizes="(max-width: 523px) 100vw, 523px"
                                      srcset="
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/pachymetria_04okulistyka06.jpg         523w,
                                        https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/pachymetria_04okulistyka06-300x188.jpg 300w
                                      "
                                      data-ll-status="loaded"
                                    /><noscript
                                      ><img
                                        decoding="async"
                                        width="523"
                                        height="327"
                                        src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/pachymetria_04okulistyka06.jpg"
                                        class="attachment-large size-large wp-image-768"
                                        alt=""
                                        srcset="
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/pachymetria_04okulistyka06.jpg         523w,
                                          https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/pachymetria_04okulistyka06-300x188.jpg 300w
                                        "
                                        sizes="(max-width: 523px) 100vw, 523px"
                                    /></noscript>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-7cym39g elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="7cym39g"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-gz4t233"
                      data-id="gz4t233"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-ldqwq0d elementor-widget elementor-widget-text-editor"
                          data-id="ldqwq0d"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <h3>
                              &nbsp;
                              <p align="justify">
                                To nowoczesna metoda obrazowania siatkówki.
                                Wynik badania jest bardzo dokładny – obrazy są
                                poddawane szczegółowej analizie przez
                                specjalistyczne oprogramowanie. Pozwala to na
                                precyzyjną analizę struktury siatkówki.
                                Koherentna tomografia uwidacznia z ogromną
                                szczegółowością zmiany chorobowe siatkówki i
                                naczyniówki oka. Są to między innymi:
                              </p>
                            </h3>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-erp5xjt elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                          data-id="erp5xjt"
                          data-element_type="widget"
                          data-widget_type="icon-list.default"
                        >
                          <div class="elementor-widget-container">
                            <ul class="elementor-icon-list-items">
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  >druzy, wysięki, obrzęk,
                                  <b>zwyrodnienie plamki żółtej</b></span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  >cukrzycowy obrzęk plamki</span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-dot-circle-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  >błony nasiatkówkowe</span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  >otwór w plamce</span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  >centralna retinopatia surowicza</span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  >znamię naczynówki</span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  ><b>OCT moduł plamkowy</b></span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  ><b>OCT moduł jaskrowy</b> (ocena tarczy nerwu
                                  II i parametrów jaskrowych w plamce)</span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  ><b>OCT tarczy nerwu wzrokowego</b></span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  ><b
                                    >Ocena szerokości kąta przesączania</b
                                  ></span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  ><b
                                    >Ocena głębokości komory przedniej</b
                                  ></span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  ><b>OCT przedniego odcinka oka</b></span
                                >
                              </li>
                              <li class="elementor-icon-list-item">
                                <span class="elementor-icon-list-icon">
                                  <i
                                    class="fa fa-arrow-circle-o-right"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span class="elementor-icon-list-text"
                                  >i wiele innych...</span
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </article>
        </div>
        <Sidebar />
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>

<style></style>
