<template>
  <div class="art-content-layout-row">
    <div class="art-layout-cell art-content">
      <article
        id="post-185"
        class="art-post art-article post-185 page type-page status-publish hentry"
      >
        <h1 class="art-postheader">HRT</h1>
        <div class="art-postcontent clearfix">
          <div
            data-elementor-type="wp-post"
            data-elementor-id="185"
            class="elementor elementor-185 elementor-bc-flex-widget"
          >
            <section
              class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-imc7ahe elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="imc7ahe"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-q4f6gbu"
                  data-id="q4f6gbu"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-vaetl1b elementor-widget elementor-widget-heading"
                      data-id="vaetl1b"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h1
                          class="elementor-heading-title elementor-size-default"
                        >
                          Badanie tarczy nerwu wzrokowego (HRT)
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-4fsjv6v elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="4fsjv6v"
              data-element_type="section"
            >
              <div class="elementor-container elementor-column-gap-default">
                <div
                  class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1bl68g5"
                  data-id="1bl68g5"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-n7i9xi6 elementor-widget elementor-widget-heading"
                      data-id="n7i9xi6"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <h2
                          class="elementor-heading-title elementor-size-default"
                        >
                          Skaningowa tomografia siatkówkowa
                        </h2>
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-3z47xek elementor-widget elementor-widget-text-editor"
                      data-id="3z47xek"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div class="elementor-widget-container">
                        <p>&nbsp;</p>
                        <h3 align="justify">
                          Jednym z najistotniejszych badań na
                          <strong>jaskrę&nbsp;</strong>jest HRT – nowoczesna
                          metoda diagnostyczna – ilościowe pomiary struktury
                          tarczy nerwu wzrokowego. Umożliwia wykrywanie
                          subtelnych ubytków włókien nerwowych przed pojawieniem
                          się ich w polu widzenia. Wynikiem badania jest obraz,
                          który umożliwia postawienie dokładnej diagnozy i
                          ustalenie dalszego postępowania&nbsp;w leczeniu.
                          HRT&nbsp; II – konfokalny skaningowy oftalmoskop
                          laserowy służy do obrazowania przekrojów tarczy nerwu
                          wzrokowego i siatkówki wokół tarczy.Uzyskujemy
                          trójwymiarowy obraz bez konieczności rozszerzenia
                          żrenicy.Wydruki zawierają opisy kształtu tarczy oraz
                          grubości włókien nerwowych.Badania HRT , GDX, OCT
                          pozwalają na obiektywne, ilościowe i&nbsp;specyficzne
                          metody diagnozowania jaskry i&nbsp;monitorowanie jej
                          progresji.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2qjma4t"
                  data-id="2qjma4t"
                  data-element_type="column"
                >
                  <div
                    class="elementor-widget-wrap elementor-element-populated"
                  >
                    <div
                      class="elementor-element elementor-element-t6lapws elementor-widget elementor-widget-image"
                      data-id="t6lapws"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div class="elementor-widget-container">
                        <img
                          fetchpriority="high"
                          decoding="async"
                          width="1024"
                          height="576"
                          src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy-1024x576.jpg"
                          class="attachment-large size-large wp-image-698 lazyloaded"
                          alt=""
                          sizes="(max-width: 1024px) 100vw, 1024px"
                          srcset="
                            https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy-1024x576.jpg 1024w,
                            https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy-300x169.jpg   300w,
                            https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy-768x432.jpg   768w,
                            https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy.jpg          1713w
                          "
                          data-ll-status="loaded"
                        /><noscript
                          ><img
                            fetchpriority="high"
                            decoding="async"
                            width="1024"
                            height="576"
                            src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy-1024x576.jpg"
                            class="attachment-large size-large wp-image-698"
                            alt=""
                            srcset="
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy-1024x576.jpg 1024w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy-300x169.jpg   300w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy-768x432.jpg   768w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01242-Copy.jpg          1713w
                            "
                            sizes="(max-width: 1024px) 100vw, 1024px"
                        /></noscript>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
    </div>

    <Sidebar/>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default {
  components: {
    Sidebar
  }
};
</script>

<style></style>
