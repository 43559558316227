<template>
  <head>
    <link rel="stylesheet" href="./css/post-1303.css" />
    <link rel="stylesheet" href="./css/font-awesome.min.css" />
  </head>
  <div class="art-layout-cell art-content">
    <article
      id="post-180"
      class="art-post art-article post-180 page type-page status-publish hentry"
    >
      <h1 class="art-postheader">POLE WIDZENIA</h1>
      <div class="art-postcontent clearfix">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="180"
          class="elementor elementor-180"
        >
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-b6endjm elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="b6endjm"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-pq0hm57"
                data-id="pq0hm57"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-4r1tnow elementor-widget elementor-widget-heading"
                    data-id="4r1tnow"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h1
                        class="elementor-heading-title elementor-size-default"
                      >
                        Badanie pola widzenia
                      </h1>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-yny2xft elementor-widget elementor-widget-text-editor"
                    data-id="yny2xft"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>&nbsp;</h3>
                      <h3 align="justify">
                        W przychodni wykonujemy badanie pola widzenia.
                        Jest to bardzo ważna badanie okulistyczne, które w
                        subiektywny sposób określa zakres widzenia pacjenta.
                        Wykrywane są w ten sposób ubytki w polu widzenia nie
                        dostrzegane przez pacjenta, często&nbsp;na obwodzie, nie
                        w centrum osi widzenia.
                      </h3>
                      <h3 align="justify">&nbsp;</h3>
                      <h3 align="justify">
                        Badanie pola widzenia stosowane &nbsp;jest przy:
                      </h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-pwpu8ns elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="pwpu8ns"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text">jaskrze</span>
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >chorobach plamki żółtej</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >neuropatiach wzrokowych</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >druzach tarczy nerwu II</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >zaburzeniach neurologicznych</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8z7jhz6"
                data-id="8z7jhz6"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-klge4u1 elementor-widget elementor-widget-image"
                    data-id="klge4u1"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <a
                        href="http://okulistyka.siedlce.pl/wp-content/uploads/2017/05/okulistyka03.jpg"
                        data-elementor-open-lightbox="yes"
                        data-elementor-lightbox-title="okulistyka03"
                        data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6Nzc2LCJ1cmwiOiJodHRwczpcL1wvb2t1bGlzdHlrYS5zaWVkbGNlLnBsXC93cC1jb250ZW50XC91cGxvYWRzXC8yMDE3XC8wNVwvb2t1bGlzdHlrYTAzLmpwZyJ9"
                      >
                        <img
                          style="border-style: solid"
                          decoding="async"
                          src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/okulistyka03.jpg"
                          title="okulistyka03"
                          alt="okulistyka03"
                          loading="lazy"
                          class="lazyloaded"
                          data-ll-status="loaded"
                        /><noscript
                          ><img
                            decoding="async"
                            src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/okulistyka03.jpg"
                            title="okulistyka03"
                            alt="okulistyka03"
                            loading="lazy"
                        /></noscript>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-qfz3gjh elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="qfz3gjh"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-vfscukr"
                data-id="vfscukr"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-d3is16w elementor-widget elementor-widget-text-editor"
                    data-id="d3is16w"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>
                        Badanie pola widzenia jest wykonywane przez doświadczoną
                        pielęgniarkę.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </article>
  </div>
<Sidebar/>
  <!--  -->
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default {
    components: {
        Sidebar
    }
}
</script>
<style></style>
