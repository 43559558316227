<template>
  <head>
    <link rel="stylesheet" href="./css/post-1303.css" />
    <link rel="stylesheet" href="./css/post-167.css" />
    <link rel="stylesheet" href="./css/post-187.css" />
    <link rel="stylesheet" href="./css/all.min.css" />
    <link rel="stylesheet" href="./css/font-awesome.min.css" />
    <link rel="stylesheet" href="./css/frontend.min.css" />
  </head>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="art-postcontent clearfix">
      <div
        data-elementor-type="wp-post"
        data-elementor-id="9"
        class="elementor elementor-9"
      >
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-h7e55xy elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="h7e55xy"
          data-element_type="section"
        >
          <CarouselComponent :slides="slides"></CarouselComponent>
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-msf97f3"
              data-id="msf97f3"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-ejvw9nx elementor-arrows-position-inside elementor-pagination-position-outside elementor-invisible elementor-widget elementor-widget-image-carousel"
                  data-id="ejvw9nx"
                  data-element_type="widget"
                  data-settings=""
                  slides_to_show
                  data-widget_type="image-carousel.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      style="height: 550px"
                      class="elementor-image-carousel-wrapper swiper-container"
                      dir="ltr"
                    >
                      <CarouselComponent :slides="slides"></CarouselComponent>
                      <div
                        class="elementor-image-carousel swiper-wrapper swiper-image-stretch"
                        aria-live="off"
                      >
                        <div
                          class="swiper-slide"
                          role="group"
                          aria-roledescription="slide"
                          aria-label="1 of 6"
                        >
                          <figure class="swiper-slide-inner">
                            <img
                              decoding="async"
                              class="swiper-slide-image"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                              alt="Optyka"
                              data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01247-Copy-1.jpg"
                            /><noscript
                              ><img
                                decoding="async"
                                class="swiper-slide-image"
                                src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01247-Copy-1.jpg"
                                alt="Optyka"
                            /></noscript>
                            <figcaption
                              class="elementor-image-carousel-caption"
                            >
                              Optyka
                            </figcaption>
                          </figure>
                        </div>
                        <div
                          class="swiper-slide"
                          role="group"
                          aria-roledescription="slide"
                          aria-label="2 of 6"
                        >
                          <figure class="swiper-slide-inner">
                            <img
                              decoding="async"
                              class="swiper-slide-image"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                              alt="Okulistyka dziecięca"
                              data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01218-Copy-1.jpg"
                            /><noscript
                              ><img
                                decoding="async"
                                class="swiper-slide-image"
                                src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01218-Copy-1.jpg"
                                alt="Okulistyka dziecięca"
                            /></noscript>
                            <figcaption
                              class="elementor-image-carousel-caption"
                            >
                              Okulistyka dziecięca
                            </figcaption>
                          </figure>
                        </div>
                        <div
                          class="swiper-slide"
                          role="group"
                          aria-roledescription="slide"
                          aria-label="3 of 6"
                        >
                          <figure class="swiper-slide-inner">
                            <img
                              decoding="async"
                              class="swiper-slide-image"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                              alt="Nowoczesna aparatura"
                              data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01225-Copy-1.jpg"
                            /><noscript
                              ><img
                                decoding="async"
                                class="swiper-slide-image"
                                src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01225-Copy-1.jpg"
                                alt="Nowoczesna aparatura"
                            /></noscript>
                            <figcaption
                              class="elementor-image-carousel-caption"
                            >
                              Nowoczesna aparatura
                            </figcaption>
                          </figure>
                        </div>
                        <div
                          class="swiper-slide"
                          role="group"
                          aria-roledescription="slide"
                          aria-label="4 of 6"
                        >
                          <figure class="swiper-slide-inner">
                            <img
                              decoding="async"
                              class="swiper-slide-image"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                              alt="Kompleksowa diagnostyka"
                              data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01237-Copy-1.jpg"
                            /><noscript
                              ><img
                                decoding="async"
                                class="swiper-slide-image"
                                src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01237-Copy-1.jpg"
                                alt="Kompleksowa diagnostyka"
                            /></noscript>
                            <figcaption
                              class="elementor-image-carousel-caption"
                            >
                              Kompleksowa diagnostyka
                            </figcaption>
                          </figure>
                        </div>
                        <div
                          class="swiper-slide"
                          role="group"
                          aria-roledescription="slide"
                          aria-label="5 of 6"
                        >
                          <figure class="swiper-slide-inner">
                            <img
                              decoding="async"
                              class="swiper-slide-image"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                              alt="Doświadczona kadra"
                              data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01227-Copy-1.jpg"
                            /><noscript
                              ><img
                                decoding="async"
                                class="swiper-slide-image"
                                src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/DSC01227-Copy-1.jpg"
                                alt="Doświadczona kadra"
                            /></noscript>
                            <figcaption
                              class="elementor-image-carousel-caption"
                            >
                              Doświadczona kadra
                            </figcaption>
                          </figure>
                        </div>
                        <div
                          class="swiper-slide"
                          role="group"
                          aria-roledescription="slide"
                          aria-label="6 of 6"
                        >
                          <figure class="swiper-slide-inner">
                            <img
                              decoding="async"
                              class="swiper-slide-image"
                              src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                              alt="Wykwalifikowany zespół"
                              data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01263-e1640463919749.jpg"
                            /><noscript
                              ><img
                                decoding="async"
                                class="swiper-slide-image"
                                src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/DSC01263-e1640463919749.jpg"
                                alt="Wykwalifikowany zespół"
                            /></noscript>
                            <figcaption
                              class="elementor-image-carousel-caption"
                            >
                              Wykwalifikowany zespół
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                      <div
                        class="elementor-swiper-button elementor-swiper-button-prev"
                        role="button"
                        tabindex="0"
                      >
                        <i aria-hidden="true" class="eicon-chevron-left"></i>
                      </div>
                      <div
                        class="elementor-swiper-button elementor-swiper-button-next"
                        role="button"
                        tabindex="0"
                      >
                        <i aria-hidden="true" class="eicon-chevron-right"></i>
                      </div>

                      <div class="swiper-pagination"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-rmoed84"
              data-id="rmoed84"
              data-element_type="column"
            >
              <!-- <CarouselComponent :slides="slides"></CarouselComponent> -->

              <div class="elementor-widget-wrap elementor-element-populated">
                <!-- <CarouselComponent :slides="slides"></CarouselComponent> -->
                <div
                  class="elementor-element elementor-element-erd5zc7 elementor-widget elementor-widget-google_maps"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-custom-embed">
                      <iframe
                        loading="lazy"
                        src="about:blank"
                        title="Starowiejska 30, 08-110 Siedlce"
                        aria-label="Starowiejska 30, 08-110 Siedlce"
                        data-rocket-lazyload="fitvidscompatible"
                        data-lazy-src="https://maps.google.com/maps?q=Starowiejska%2030%2C%2008-110%20Siedlce&amp;t=m&amp;z=12&amp;output=embed&amp;iwloc=near"
                      ></iframe
                      ><noscript
                        ><iframe
                          loading="lazy"
                          src="https://maps.google.com/maps?q=Starowiejska%2030%2C%2008-110%20Siedlce&t=m&z=12&output=embed&iwloc=near"
                          title="Starowiejska 30, 08-110 Siedlce"
                          aria-label="Starowiejska 30, 08-110 Siedlce"
                        ></iframe
                      ></noscript>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-46wtfng elementor-widget elementor-widget-google_maps"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-custom-embed">
                      <iframe
                        loading="lazy"
                        src="about:blank"
                        title="Ul. Błonie 14, Siedlce"
                        aria-label="Ul. Błonie 14, Siedlce"
                        data-rocket-lazyload="fitvidscompatible"
                        data-lazy-src="https://maps.google.com/maps?q=Ul.%20B%C5%82onie%2014%2C%20Siedlce&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
                      ></iframe
                      ><noscript
                        ><iframe
                          loading="lazy"
                          src="https://maps.google.com/maps?q=Ul.%20B%C5%82onie%2014%2C%20Siedlce&t=m&z=15&output=embed&iwloc=near"
                          title="Ul. Błonie 14, Siedlce"
                          aria-label="Ul. Błonie 14, Siedlce"
                        ></iframe
                      ></noscript>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-9euzmxn elementor-widget elementor-widget-button"
                  data-id="9euzmxn"
                  data-element_type="widget"
                  data-widget_type="button.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-button-wrapper">
                      <a
                        class="elementor-button elementor-button-link elementor-size-sm"
                        href="mailto:biuro@okulistyka.siedlce.pl"
                      >
                        <span class="elementor-button-content-wrapper">
                          <span
                            class="elementor-button-icon elementor-align-icon-left"
                          >
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                          </span>
                          <span class="elementor-button-text"
                            >biuro@okulistyka.siedlce.pl</span
                          >
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-ruurzgo elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="ruurzgo"
          data-element_type="section"
          background_background
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="has_eae_slider elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-jttvuxo"
              data-id="jttvuxo"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-jtf70w5 elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="jtf70w5"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-image-box-wrapper">
                      <figure class="elementor-image-box-img">
                        <img
                          fetchpriority="high"
                          decoding="async"
                          width="478"
                          height="478"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20478%20478'%3E%3C/svg%3E"
                          class="elementor-animation-bounce-in attachment-full size-full wp-image-1308"
                          alt=""
                          data-lazy-srcset="https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/zespół.jpg 478w, https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/zespół-300x300.jpg 300w, https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/zespół-150x150.jpg 150w"
                          data-lazy-sizes="(max-width: 478px) 100vw, 478px"
                          data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/zespół.jpg"
                        /><noscript
                          ><img
                            fetchpriority="high"
                            decoding="async"
                            width="478"
                            height="478"
                            src="https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/zespół.jpg"
                            class="elementor-animation-bounce-in attachment-full size-full wp-image-1308"
                            alt=""
                            srcset="
                              https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/zespół.jpg         478w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/zespół-300x300.jpg 300w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/zespół-150x150.jpg 150w
                            "
                            sizes="(max-width: 478px) 100vw, 478px"
                        /></noscript>
                      </figure>
                      <div class="elementor-image-box-content">
                        <h3 class="elementor-image-box-title">
                          <center>
                            <router-link to="/zespol">O NAS</router-link>
                            <!-- <a href="./zespol/">O NAS</a> -->
                          </center>
                        </h3>
                        <p class="elementor-image-box-description"></p>
                        <div
                          style="
                            color: #ffffff;
                            font-size: 16px;
                            font-weight: 300;
                            text-align: jutify;
                          "
                        >
                          Dla wszystkich ludzi pracujących w naszej Przychodni
                          najważniejsze jest profesjonalne podejście do pacjenta
                        </div>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="has_eae_slider elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-5ssnvcn"
              data-id="5ssnvcn"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-hi69vgl elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="hi69vgl"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-image-box-wrapper">
                      <figure class="elementor-image-box-img">
                        <img
                          decoding="async"
                          width="685"
                          height="685"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20685%20685'%3E%3C/svg%3E"
                          class="elementor-animation-bounce-in attachment-full size-full wp-image-1316"
                          alt=""
                          data-lazy-srcset="https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/dla-okulisty-1-1.jpg 685w, https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/dla-okulisty-1-1-300x300.jpg 300w, https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/dla-okulisty-1-1-150x150.jpg 150w"
                          data-lazy-sizes="(max-width: 685px) 100vw, 685px"
                          data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/dla-okulisty-1-1.jpg"
                        /><noscript
                          ><img
                            decoding="async"
                            width="685"
                            height="685"
                            src="https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/dla-okulisty-1-1.jpg"
                            class="elementor-animation-bounce-in attachment-full size-full wp-image-1316"
                            alt=""
                            srcset="
                              https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/dla-okulisty-1-1.jpg         685w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/dla-okulisty-1-1-300x300.jpg 300w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/dla-okulisty-1-1-150x150.jpg 150w
                            "
                            sizes="(max-width: 685px) 100vw, 685px"
                        /></noscript>
                      </figure>
                      <div class="elementor-image-box-content">
                        <h3 class="elementor-image-box-title">
                          <center>
                            <router-link to="/optyka">OPTYKA</router-link>
                            <!-- <a href="./optyka/">OPTYKA</a> -->
                          </center>
                        </h3>
                        <p class="elementor-image-box-description"></p>
                        <div
                          style="
                            color: #ffffff;
                            font-size: 16px;
                            font-weight: 300;
                            text-align: jutify;
                          "
                        >
                          Podstawową część naszej oferty, stanowią usługi w
                          zakresie wyrobu okularów korekcyjnych. Wykonujemy
                          wiele ich typów
                        </div>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="has_eae_slider elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-zrbfbzw"
              data-id="zrbfbzw"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-juqimve elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="juqimve"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-image-box-wrapper">
                      <figure class="elementor-image-box-img">
                        <img
                          decoding="async"
                          width="472"
                          height="472"
                          src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20472%20472'%3E%3C/svg%3E"
                          class="elementor-animation-bounce-in attachment-full size-full wp-image-1318"
                          alt=""
                          data-lazy-srcset="https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/o-nas-2.jpg 472w, https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/o-nas-2-300x300.jpg 300w, https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/o-nas-2-150x150.jpg 150w"
                          data-lazy-sizes="(max-width: 472px) 100vw, 472px"
                          data-lazy-src="https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/o-nas-2.jpg"
                        /><noscript
                          ><img
                            decoding="async"
                            width="472"
                            height="472"
                            src="https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/o-nas-2.jpg"
                            class="elementor-animation-bounce-in attachment-full size-full wp-image-1318"
                            alt=""
                            srcset="
                              https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/o-nas-2.jpg         472w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/o-nas-2-300x300.jpg 300w,
                              https://okulistyka.siedlce.pl/wp-content/uploads/2020/08/o-nas-2-150x150.jpg 150w
                            "
                            sizes="(max-width: 472px) 100vw, 472px"
                        /></noscript>
                      </figure>
                      <div class="elementor-image-box-content">
                        <h3 class="elementor-image-box-title">
                          <center>
                            <router-link to="/konsultacja"
                            @click="$store.commit('handleClick1')">DLA PACJENTA</router-link>
                            <!-- <a href="./konsultacja/">DLA&nbsp;PACJenTA</a> -->
                          </center>
                        </h3>
                        <p class="elementor-image-box-description"></p>
                        <div
                          style="
                            color: #ffffff;
                            font-size: 16px;
                            font-weight: 300;
                            text-align: center;
                          "
                        >
                          W ramach naszych konsultacji okulistycznych prowadzimy
                          standardowo...
                        </div>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-xjutfim elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="xjutfim"
          data-element_type="section"
          background_background
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-tlpfcve"
              data-id="tlpfcve"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-dxtliov elementor-widget elementor-widget-heading"
                  data-id="dxtliov"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h3 class="elementor-heading-title elementor-size-large">
                      <center>Okulistyka w Siedlcach</center>
                    </h3>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-04abac4 eae-icon-view-default elementor-widget elementor-widget-wts-textseparator"
                  data-id="04abac4"
                  data-element_type="widget"
                  data-widget_type="wts-textseparator.default"
                >
                  <div class="elementor-widget-container">
                    <div
                      class="wts-eae-textseparator sep-align-center icon-yes icon-before"
                    >
                      <div class="eae-sep-holder sep-left">
                        <div class="eae-sep-lines"></div>
                      </div>

                      <div class="eae-separator-icon-wrapper">
                        <div class="eae-separator-icon-inner">
                          <i class=""></i>
                        </div>
                      </div>

                      <div class="eae-sep-holder sep-right">
                        <div class="eae-sep-lines"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-7433723 elementor-section-height-min-height elementor-section-content-middle elementor-section-full_width elementor-section-height-default"
                  data-id="7433723"
                  data-element_type="section"
                  background_background
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-3ffc1dd"
                      data-id="3ffc1dd"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-b0a7fdb elementor-widget elementor-widget-heading"
                          data-id="b0a7fdb"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h5
                              class="elementor-heading-title elementor-size-large"
                            >
                              <center>Posiadamy Laser ND: YAG Ultra q</center>
                            </h5>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-636b23b elementor-widget elementor-widget-text-editor"
                          data-id="636b23b"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              <strong
                                >Wykonujem zabiegi kapsulotomii laserowej i
                                irydotomii.</strong
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  class="elementor-element elementor-element-1623ae7 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                  data-id="1623ae7"
                  data-element_type="widget"
                  data-widget_type="divider.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-divider">
                      <span class="elementor-divider-separator"> </span>
                    </div>
                  </div>
                </div>
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-ab5ebd0 elementor-section-height-min-height elementor-section-content-middle elementor-section-full_width elementor-section-height-default"
                  data-id="ab5ebd0"
                  data-element_type="section"
                  background_background
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-a8c52e5"
                      data-id="a8c52e5"
                      data-element_type="column"
                      background_background
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-cb14f39 elementor-widget elementor-widget-spacer"
                          data-id="cb14f39"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-spacer">
                              <div class="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e970ab1"
                      data-id="e970ab1"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-c21099c elementor-widget elementor-widget-heading"
                          data-id="c21099c"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h5
                              class="elementor-heading-title elementor-size-large"
                            >
                              wieloletnie doświadczenie
                            </h5>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-185f366 elementor-widget elementor-widget-text-editor"
                          data-id="185f366"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              W naszym centrum pracują ludzie z wieloletnim
                              doświaczeniem. Dysponujemy nowoczesnym zapleczem
                              diagnostycznym umożliwiającym nieinwazyjne
                              obrazowanie struktur gałki ocznej
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ac7ec4e"
                      data-id="ac7ec4e"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-34d44b0 elementor-align-center elementor-widget elementor-widget-button"
                          data-id="34d44b0"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <router-link
                                class="elementor-button elementor-button-link elementor-size-sm"
                                
                                to="/zespol"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-text"
                                    >Czytaj dalej</span
                                  >
                                </span>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-afugzah elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default"
                  data-id="afugzah"
                  data-element_type="section"
                  background_background
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-lhskfln"
                      data-id="lhskfln"
                      data-element_type="column"
                      background_background
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-pfcxluz elementor-widget elementor-widget-spacer"
                          data-id="pfcxluz"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-spacer">
                              <div class="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ynkshth"
                      data-id="ynkshth"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-jpfiplw elementor-widget elementor-widget-heading"
                          data-id="jpfiplw"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-large"
                            >
                              nowoczesna aparatura
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-ysheirz elementor-widget elementor-widget-text-editor"
                          data-id="ysheirz"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              Wykonujemy wszystkie badania na nowoczesnej
                              aparaturze. Prowadzimy kompleksowe badania
                              diagnostyczne i terapię okulistyczną. Leczymy
                              zapalenia, infekcje i choroby alergiczne.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-eqvlmba"
                      data-id="eqvlmba"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-zekcbtp elementor-align-center elementor-widget elementor-widget-button"
                          data-id="zekcbtp"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <router-link
                                class="elementor-button elementor-button-link elementor-size-sm"
                                @click="$store.commit('handleClick1')"
                                to="/doborpomocy"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-text"
                                    >czytaj dalej</span
                                  >
                                </span>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-vikyanh elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default"
                  data-id="vikyanh"
                  data-element_type="section"
                  background_background
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-xlzizcp"
                      data-id="xlzizcp"
                      data-element_type="column"
                      background_background
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-bajcgui elementor-widget elementor-widget-spacer"
                          data-id="bajcgui"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-spacer">
                              <div class="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-wppugfj"
                      data-id="wppugfj"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-kzpplky elementor-widget elementor-widget-heading"
                          data-id="kzpplky"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-large"
                            >
                              okulistyka dziecięca
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-wdnxltx elementor-widget elementor-widget-text-editor"
                          data-id="wdnxltx"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>
                              Do każdego pacjenta podchodzimy z cierpliwością i
                              zaangażowaniem czy to dorosłego czy dziecka
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-xnaljks"
                      data-id="xnaljks"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-looaqyi elementor-align-center elementor-widget elementor-widget-button"
                          data-id="looaqyi"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-button-wrapper">
                              <router-link
                                class="elementor-button elementor-button-link elementor-size-sm"
                                @click="$store.commit('handleClick1')"
                                to="/synoptofor"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <span class="elementor-button-text"
                                    >Czytaj dalej</span
                                  >
                                </span>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-bmbr1t7 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="bmbr1t7"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="has_eae_slider elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-msspxto"
              data-id="msspxto"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-39f5h87 elementor-widget elementor-widget-heading"
                  data-id="39f5h87"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      KONTAKT
                    </h1>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-d0mehu2 elementor-widget elementor-widget-heading"
                  data-id="d0mehu2"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Adresy
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-fa4v1m3 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                  data-id="fa4v1m3"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div class="elementor-widget-container">
                    <ul class="elementor-icon-list-items">
                      <li class="elementor-icon-list-item">
                        <span class="elementor-icon-list-icon">
                          <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="elementor-icon-list-text"
                          >ul. Starowiejska 30</span
                        >
                      </li>
                      <li class="elementor-icon-list-item">
                        <span class="elementor-icon-list-icon">
                          <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="elementor-icon-list-text"
                          >ul. Błonie 14</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-emw0ucl elementor-widget elementor-widget-heading"
                  data-id="emw0ucl"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      Zapisy
                    </h2>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-gh7rcxe elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                  data-id="gh7rcxe"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div class="elementor-widget-container">
                    <ul class="elementor-icon-list-items">
                      <li class="elementor-icon-list-item">
                        <span class="elementor-icon-list-icon">
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                        </span>
                        <span class="elementor-icon-list-text"
                          >Pn-Sob. od 10:00 do 18:00</span
                        >
                      </li>
                      <li class="elementor-icon-list-item">
                        <span class="elementor-icon-list-icon">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </span>
                        <span class="elementor-icon-list-text"
                          >25 633 30 30</span
                        >
                      </li>
                      <li class="elementor-icon-list-item">
                        <span class="elementor-icon-list-icon">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </span>
                        <span class="elementor-icon-list-text"
                          >25 633 30 33</span
                        >
                      </li>
                      <li class="elementor-icon-list-item">
                        <span class="elementor-icon-list-icon">
                          <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </span>
                        <span class="elementor-icon-list-text"
                          >biuro@okulistyka.siedlce.pl</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="has_eae_slider elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-t9h44ca"
              data-id="t9h44ca"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-tyd38d1 elementor-widget elementor-widget-google_maps"
                  data-id="tyd38d1"
                  data-element_type="widget"
                  data-widget_type="google_maps.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-custom-embed">
                      <iframe
                        loading="lazy"
                        src="about:blank"
                        title="ul. Starowiejska 30, Siedlce"
                        aria-label="ul. Starowiejska 30, Siedlce"
                        data-rocket-lazyload="fitvidscompatible"
                        data-lazy-src="https://maps.google.com/maps?q=ul.%20Starowiejska%2030%2C%20Siedlce&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
                      ></iframe
                      ><noscript
                        ><iframe
                          loading="lazy"
                          src="https://maps.google.com/maps?q=ul.%20Starowiejska%2030%2C%20Siedlce&t=m&z=15&output=embed&iwloc=near"
                          title="ul. Starowiejska 30, Siedlce"
                          aria-label="ul. Starowiejska 30, Siedlce"
                        ></iframe
                      ></noscript>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-2wemkg5 elementor-widget elementor-widget-google_maps"
                  data-id="2wemkg5"
                  data-element_type="widget"
                  data-widget_type="google_maps.default"
                >
                  <div class="elementor-widget-container">
                    <div class="elementor-custom-embed">
                      <iframe
                        loading="lazy"
                        src="about:blank"
                        title="ul. Błonie 14, Siedlce"
                        aria-label="ul. Błonie 14, Siedlce"
                        data-rocket-lazyload="fitvidscompatible"
                        data-lazy-src="https://maps.google.com/maps?q=ul.%20B%C5%82onie%2014%2C%20Siedlce&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
                      ></iframe
                      ><noscript
                        ><iframe
                          loading="lazy"
                          src="https://maps.google.com/maps?q=ul.%20B%C5%82onie%2014%2C%20Siedlce&t=m&z=15&output=embed&iwloc=near"
                          title="ul. Błonie 14, Siedlce"
                          aria-label="ul. Błonie 14, Siedlce"
                        ></iframe
                      ></noscript>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
console.log("Home is loaded");
import CarouselComponent from "@/components/carousel/CarouselComponent.vue";
export default {
  components: { CarouselComponent },
  data: () => ({
    slides: [
      "/slider/DSC01247-Copy-1_optyka.jpg",
      "/slider/DSC01218-Copy-1_okulistyka_dziecieca.jpg",
      "/slider/DSC01225-Copy-1_nowoczesna aparatura.jpg",
      "/slider/DSC01237-Copy-1_kompleksowa diagnostyka.jpg",
      "/slider/DSC01227-Copy-1_doswiadczona kadra.jpg",
      "/slider/DSC01263-e1640463919749_wykwalifikowany zespol.jpg",
      // "https://i.picsum.photos/id/1037/900/400.jpg?hmac=QcjNl48cKsn8x3bOuYEdPliJsSZo_8VxHxRrLWtM5Kg"
    ],
  }),
};
</script>
<!-- <script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  }
}
</script> -->
