<template>
  <div id="art-main">
    <!-- <div id="app">{{ message }}</div> -->

    <nav class="art-nav">
      <ul class="art-hmenu menu-3">
        <li class="menu-item-20">
          <router-link
            to="/" 
            
            @click="$store.commit('handleReset')">Home</router-link>
          <!-- <a
            href="/"
            
            
            >Home</a > -->
        </li>
        <li class="menu-item-173">
          <router-link
            to="/zespol" 
            @click="$store.commit('handleReset')"
            >ZESPÓŁ</router-link>
        </li>
        <li class="menu-item-174">
          <a :class="{ 'router-link-exact-active':  $store.state.isActive1 }" title="BADANIA DIAGNOSTYCZNE"
            >BADANIA DIAGNOSTYCZNE</a>
          <ul @click="$store.commit('handleClick1')">
            <li class="menu-item-231">
              <router-link to="/konsultacja"  >KONSULTACJA</router-link>
            </li>
            <li class="menu-item-237">
              <router-link to="/polewidzenia" >POLE WIDZENIA</router-link>
              <!-- <a
                    title="POLE WIDZENIA"
                    href="https://okulistyka.siedlce.pl/pole-widzenia/"
                    >POLE WIDZENIA</a> -->
            </li>
            <li class="menu-item-234">
              <router-link to="/fdtmatrix" >FDT-MATRIX</router-link>
              <!-- <a
                    title="FDT-MATRIX"
                    href="https://okulistyka.siedlce.pl/fpt-matrix/"
                    >FDT-MATRIX</a> -->
            </li>
            <li class="menu-item-235">
              <router-link to="/hrt">HRT</router-link>
              <!-- <a title="HRT" href="https://okulistyka.siedlce.pl/hrt/">HRT</a> -->
            </li>
            <li class="menu-item-191">
              <router-link to="/oct">OCT</router-link
              >
              <!-- <a title="OCT" href="https://okulistyka.siedlce.pl/oct/">OCT</a> -->
              <ul>
                <li class="menu-item-744">
                  <a title="TARCZY NERWU WROKOWEGO" href="#"
                    >TARCZY NERWU WROKOWEGO</a
                  >
                </li>
                <li class="menu-item-745">
                  <a title="PLAMKI" href="#">PLAMKI</a>
                </li>
                <li class="menu-item-746">
                  <a title="MODUŁ JASKROWY" href="#">MODUŁ JASKROWY</a>
                  <ul>
                    <li class="menu-item-1143">
                      <a title="GRUBOŚĆ ROGÓWKI" href="#">GRUBOŚĆ ROGÓWKI</a>
                    </li>
                    <li class="menu-item-1144">
                      <a title="OCENA KĄTA PRZESĄCZANIA" href="#"
                        >OCENA KĄTA PRZESĄCZANIA</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="menu-item-605">
                <router-link to="/pachymetria" >PACHYMETRIA</router-link>
              <!-- <a
                title="PACHYMETRIA"
                href="https://okulistyka.siedlce.pl/pachymetria/"
                >PACHYMETRIA</a> -->
            </li>
            <li class="menu-item-629">
                <router-link to="/synoptofor" >SYNOPTOFOR</router-link>
              <!-- <a
                title="SYNOPTOFOR"
                href="https://okulistyka.siedlce.pl/synoptofor/"
                >SYNOPTOFOR</a> -->
            </li>
            <li class="menu-item-814">
                <router-link to="/usgab" >USG A/B</router-link>
              <!-- <a title="USG A/B" href="https://okulistyka.siedlce.pl/usgab/"
                >USG A/B</a> -->
            </li>
            <li class="menu-item-846">
                <router-link to="/soczewkikontaktowe" >SOCZEWKI KONTAKTOWE</router-link>
              <!-- <a
                title="SOCZEWKI KONTAKTOWE"
                href="https://okulistyka.siedlce.pl/soczewki-2/"
                >SOCZEWKI KONTAKTOWE</a> -->
            </li>
            <li class="menu-item-850">
                <router-link to="/badanienurzenca" >BADANIE W KIERUNKU NUŻEŃCA</router-link>
              <!-- <a
                title="BADANIE W KIERUNKU NUŻEŃCA"
                href="https://okulistyka.siedlce.pl/nurzeniec/"
                >BADANIE W KIERUNKU NUŻEŃCA</a> -->
            </li>
            <li class="menu-item-887">
                <router-link to="/zespolsuchegooka" >ZESPÓŁ SUCHEGO OKA</router-link>
              <!-- <a
                title="ZESPÓŁ SUCHEGO OKA"
                href="https://okulistyka.siedlce.pl/sucheoko/"
                >ZESPÓŁ SUCHEGO OKA</a> -->
            </li>
            <li class="menu-item-915">
                <router-link to="/profilaktyka" >PROFILAKTYKA</router-link>
              <!-- <a
                title="PROFILAKTYKA"
                href="https://okulistyka.siedlce.pl/profilaktyka-2/"
                >PROFILAKTYKA</a> -->
            </li>
            <li class="menu-item-928">
                <router-link to="/doborpomocy" >DOBÓR POMOCY OPTYCZNYCH DLA SŁABO WIDZĄCYCH</router-link>
              <!-- <a
                title="DOBÓR POMOCY OPTYCZNYCH DLA SŁABO WIDZĄCYCH"
                href="https://okulistyka.siedlce.pl/dobor-pomocy-optycznych-dla-slabo-widzacych/"
                >DOBÓR POMOCY OPTYCZNYCH DLA SŁABO…</a> -->
            </li>
          </ul>
        </li>
        <li class="menu-item-670">
            <router-link to="/optyka" @click="$store.commit('handleReset')" >OPTYKA</router-link>
          <!-- <a title="OPTYKA" href="/optyka/%20">OPTYKA</a> -->
        </li>
        <li class="menu-item-227">
            <router-link to="/cennik" @click="$store.commit('handleReset')">CENNIK</router-link>
          <!-- <a
            title="CENNIK"
            href="https://okulistyka.siedlce.pl/cennik/komercyjne/"
            >CENNIK</a 
          -->
        </li>
        <li class="menu-item-949">
          <a :class="{ 'router-link-exact-active': $store.state.isActive2 }" title="ĆWICZENIA" >ĆWICZENIA</a>
          <ul @click="$store.commit('handleClick2')">
            <li class="menu-item-230">
                <router-link to="/pleoptyczne" >ĆWICZENIA PLEOPTYCZNE</router-link>
              <!-- <a
                title="ĆWICZENIA PLEOPTYCZNE"
                href="https://okulistyka.siedlce.pl/cwiczenia/pleoptyczne/"
                >ĆWICZENIA PLEOPTYCZNE</a 
              -->
            </li>
            <li class="menu-item-229">
                <router-link to="/ortoptyczne" >ĆWICZENIA ORTOPTYCZNE</router-link>
              <!-- <a
                title="ĆWICZENIA ORTOPTYCZNE"
                href="https://okulistyka.siedlce.pl/cwiczenia/ortoptyczne/"
                >ĆWICZENIA ORTOPTYCZNE</a> -->
            </li>
          </ul>
        </li>
      </ul>
    </nav>

    <header class="art-header">
      <div class="art-shapes"></div>
      <div class="art-headline" data-left="49.75%">
        <a href="http://okulistyka.siedlce.pl/"
          >NZOZ Specjalistyczna Przychodnia Okulistyczna</a
        >
      </div>
      <div class="art-slogan" data-left="46.78%">
        Diagnostyka i leczenie chorób oczu
      </div>
    </header>

    <div class="art-sheet clearfix">
      <div class="art-layout-wrapper">
        <div class="art-content-layout">
          <div class="art-content-layout-row">
            <div class="art-layout-cell art-content">
              <article
                id="post-9"
                class="art-post art-article post-9 page type-page status-publish hentry"
              >
                <router-view></router-view>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="art-footer">
      <div class="art-footer-inner">
        <div class="art-footer-text">
          <div class="art-content-layout">
            <div class="art-content-layout-row">
              <div class="art-layout-cell layout-item-0" style="width: 100%">
                <p><br /></p>
              </div>
            </div>
          </div>

          <p class="art-page-footer">
            <span id="art-footnote-links"
              >
              
              created with Artisteer.</span
            >
          </p>
        </div>

        <p class="art-page-footer">
          <span id="art-footnote-links"
            >
           
            created with Artisteer.</span
          >
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      isActive1: false,
      isActive2: false,
      
    };
  },
  methods: {
    handleReset(){
      this.isActive1 = false;
      this.isActive2 = false;

    },
    // handleClick1() {
      
      
    //     // this.isActive1 = !this.isActive1;
        

        
      
   
    //   // console.log(number);
    //   // console.log(this.activeNumber);
    // },
    handleClick2(){
      this.isActive1 = false;
      this.isActive2 = true;

    }
  },
  mounted() {
    console.log("mount");
    // this.isActive1 = true;
  },
};
</script>

<style></style>
