<template>
  <head>
    <link rel="stylesheet" href="./css/post-1303.css" />
    <link rel="stylesheet" href="./css/post-167.css" />
    <link rel="stylesheet" href="./css/post-187.css" />
    <link rel="stylesheet" href="./css/all.min.css" />
    <link rel="stylesheet" href="./css/font-awesome.min.css" />
    <link rel="stylesheet" href="./css/frontend.min.css" />
  </head>
  <div class="art-layout-cell art-content">
        <article
        id="post-805"
        class="art-post art-article post-805 page type-page status-publish hentry"
        >
      <h1 class="art-postheader">USG A/B</h1>
      <div class="art-postcontent clearfix">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="805"
          class="elementor elementor-805 elementor-bc-flex-widget"
        >
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-h7u3ege elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="h7u3ege"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ysm6a7l"
                data-id="ysm6a7l"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap"></div>
              </div>
            </div>
          </section>
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-m80fov8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="m80fov8"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0wsrsmd"
                data-id="0wsrsmd"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-c5cmxor elementor-widget elementor-widget-heading"
                    data-id="c5cmxor"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h1
                        class="elementor-heading-title elementor-size-default"
                      >
                        USG A/B
                      </h1>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-vva7y8d elementor-widget elementor-widget-heading"
                    data-id="vva7y8d"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h2
                        class="elementor-heading-title elementor-size-default"
                      >
                        Ultrasonografia okulistyczna
                      </h2>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-ot1j53y elementor-widget elementor-widget-text-editor"
                    data-id="ot1j53y"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3 align="justify">
                        Jednym z badań nieinwazyjnych jest ultrasonografia
                        –USG oka. Badanie w prezentacji „A” pozwala na
                        pomiar osiowy gałki ocznej – stosowany jest w
                        przypadkach:
                      </h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-9pj8h49 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="9pj8h49"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >krótkowzroczność (dzieci i młodzież)</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon" style="margin-top:-20px;">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >u osób starszych - pomiar mocy soczewki przed
                            operacją zaćmy</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-95nip9q elementor-widget elementor-widget-text-editor"
                    data-id="95nip9q"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>
                        Obrazowanie zaburzeń w oczodole i wewnątrz oka (ciało
                        szkliste siatkówka i twardówka) możliwe jest w badaniu
                        USG oka w prezentacji „B” <br /><br />USG oka stosowane
                        jest przy chorobach takich jak:
                      </h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-to9uqjw elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="to9uqjw"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >PVD (odłączenie ciała szklistego)</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >krwotok do ciała szklistego</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >zapalenie (wysięk zapalny)</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >rozwarstwianie i odwarstwianie siatkówki</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >czerniak naczyniówki (nowotwór)</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >zapalenie twardówki</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >garbiak twardówki</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-wao2fxw elementor-widget elementor-widget-text-editor"
                    data-id="wao2fxw"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3>
                        Są wskazania do badania USG gałki ocznej takie jak:
                      </h3>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-1ikw7se elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="1ikw7se"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div class="elementor-widget-container">
                      <ul class="elementor-icon-list-items">
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >nieprzejrzyste ośrodki optyczne</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >urazy - stany pourazowe</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text"
                            >pomiar mocy soczewki, która będzie
                            wszczepiana</span
                          >
                        </li>
                        <li class="elementor-icon-list-item">
                          <span class="elementor-icon-list-icon">
                            <i
                              class="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span class="elementor-icon-list-text">cukrzyca</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-sfp6mpy"
                data-id="sfp6mpy"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <section
                    class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-ox2ekfc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="ox2ekfc"
                    data-element_type="section"
                  >
                    <div
                      class="elementor-container elementor-column-gap-default"
                    >
                      <div
                        class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77ogjtr"
                        data-id="77ogjtr"
                        data-element_type="column"
                      >
                        <div
                          class="elementor-widget-wrap elementor-element-populated"
                        >
                          <div
                            class="elementor-element elementor-element-qozqiw7 elementor-widget elementor-widget-image"
                            data-id="qozqiw7"
                            data-element_type="widget"
                            data-widget_type="image.default"
                          >
                            <div class="elementor-widget-container">
                              <img
                                fetchpriority="high"
                                decoding="async"
                                width="670"
                                height="521"
                                src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/okulistyka02_02_.png"
                                class="attachment-full size-full wp-image-1115 lazyloaded"
                                alt=""
                                sizes="(max-width: 670px) 100vw, 670px"
                                srcset="
                                  https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/okulistyka02_02_.png         670w,
                                  https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/okulistyka02_02_-300x233.png 300w
                                "
                                data-ll-status="loaded"
                              /><noscript
                                ><img
                                  fetchpriority="high"
                                  decoding="async"
                                  width="670"
                                  height="521"
                                  src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/okulistyka02_02_.png"
                                  class="attachment-full size-full wp-image-1115"
                                  alt=""
                                  srcset="
                                    https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/okulistyka02_02_.png         670w,
                                    https://okulistyka.siedlce.pl/wp-content/uploads/2017/06/okulistyka02_02_-300x233.png 300w
                                  "
                                  sizes="(max-width: 670px) 100vw, 670px"
                              /></noscript>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
     </article>
  </div>
  <!--  -->
  <Sidebar/>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
export default {
    components: {
        Sidebar
    }
};
</script>

<style></style>
