<template>
  <div class="art-layout-cell art-content">
    <article
      id="post-182"
      class="art-post art-article post-182 page type-page status-publish hentry"
    >
      <h1 class="art-postheader">FDT-MATRIX</h1>
      <div class="art-postcontent clearfix">
        <div
          data-elementor-type="wp-post"
          data-elementor-id="182"
          class="elementor elementor-182 elementor-bc-flex-widget"
        >
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-m80fov8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="m80fov8"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0wsrsmd"
                data-id="0wsrsmd"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-c5cmxor elementor-widget elementor-widget-heading"
                    data-id="c5cmxor"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div class="elementor-widget-container">
                      <h1
                        class="elementor-heading-title elementor-size-default"
                      >
                        FDT - Diagnozowanie jaskry
                      </h1>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-ot1j53y elementor-widget elementor-widget-text-editor"
                    data-id="ot1j53y"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div class="elementor-widget-container">
                      <h3 align="justify">
                        W naszej przychodni stosujemy najnowocześniejsze
                        metody diagnostyki chorób oczu w tym jaskry. Pacjenci są
                        zapraszani na&nbsp;badanie pola widzenia nowoczesnym
                        typem perymetru. Badanie służy wykrywaniu
                        najwcześniejszych zmian.
                      </h3>
                      <h3>
                        <strong>Badanie FDT –&nbsp;</strong>pozwala na
                        precyzyjną diagnostykę jaskry i monitorowanie choroby –
                        można je stosować u pacjentów z zaćmą.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="has_eae_slider elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-sfp6mpy"
                data-id="sfp6mpy"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <section
                    class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-ox2ekfc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="ox2ekfc"
                    data-element_type="section"
                  >
                    <div
                      class="elementor-container elementor-column-gap-default"
                    >
                      <div
                        class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-77ogjtr"
                        data-id="77ogjtr"
                        data-element_type="column"
                      >
                        <div
                          class="elementor-widget-wrap elementor-element-populated"
                        >
                          <div
                            class="elementor-element elementor-element-qozqiw7 elementor-widget elementor-widget-image"
                            data-id="qozqiw7"
                            data-element_type="widget"
                            data-widget_type="image.default"
                          >
                            <div class="elementor-widget-container">
                              <img
                                style="border-style: solid"
                                decoding="async"
                                src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/ght_okulistyka07.jpg"
                                title="ght_okulistyka07"
                                alt="ght_okulistyka07"
                                loading="lazy"
                                class="lazyloaded"
                                data-ll-status="loaded"
                              /><noscript
                                ><img
                                  decoding="async"
                                  src="https://okulistyka.siedlce.pl/wp-content/uploads/2017/05/ght_okulistyka07.jpg"
                                  title="ght_okulistyka07"
                                  alt="ght_okulistyka07"
                                  loading="lazy"
                              /></noscript>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          <section
            class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-h7u3ege elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="h7u3ege"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-default">
              <div
                class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ysm6a7l"
                data-id="ysm6a7l"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap"></div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </article>
  </div>

  <Sidebar />
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>

<style></style>
